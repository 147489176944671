import {
    Box,
    FormControl,
    makeStyles
} from "@material-ui/core";
import SortOptions from "./SortOptions";
import CustomCheckbox from "./CustomCheckbox";
import { TempBrandDeleteButton, TempBrandButton } from "../CoreComponents/BrandButton";
import { BrandPopover } from "../CoreComponents/BrandPopover";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        columnGap: '20px',
        marginLeft: '9px'
    },
    leftSideActionsWrapper: {
        display: 'flex',
        gap: '50px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    rightSideActionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '25px'
    },
    deleteBtn: {
        width: '120px'
    }
}));

const POPOVER_OPTIONS = [
    { label: 'File Name A→Z', order: 'ASC', sortColumn: 'fileName' },
    { label: 'File Name Z→A', order: 'DESC', sortColumn: 'fileName' },
    { label: 'Reporting Company A→Z', order: 'ASC', sortColumn: 'name' },
    { label: 'Reporting Company Z→A', order: 'DESC', sortColumn: 'name' },
    { label: 'Latest Date', order: 'DESC', sortColumn: 'reportingPeriod' },
    { label: 'Oldest Date', order: 'ASC', sortColumn: 'reportingPeriod' },
    { label: 'Notes A→Z', order: 'ASC', sortColumn: 'notes' },
    { label: 'Notes Z→A', order: 'DESC', sortColumn: 'notes' }
];

const MANAGE_REVENUE_POPOVER_TEXT = 'There is custom revenue split assigned. In order to set a new one - you need to delete all current revenue split in order to manage the revenue for all csv files in bulk.';
const MANAGE_REVENUE_POPOVER_WIDTH = 38;

const ActionsWrapper = ({
    revenueSplits,
    selectedItems,
    setSelectedItems,
    selectAllChecked,
    setSelectAllChecked,
    selectOnlyUnassigned,
    setSelectOnlyUnassigned,
    handleShowDeleteModal,
    handleShowManageModal,
    selectedOption,
    setSelectedOption,
    fetchRevenueSplits
}) => {
    const styles = useStyles();
    const [manageRevenueButtonDisabled, setManageRevenueButtonDisabled] = useState(true);

    const handleSelectAll = (event) => {
        const { checked } = event.target;

        setSelectAllChecked(checked);

        if (!checked) {
            setSelectedItems([]);
            return;
        }

        setSelectedItems(revenueSplits);
    }

    const handleSelectOnlyUnassigned = (event) => {
        const { checked } = event.target;

        setSelectedItems([]);
        setSelectOnlyUnassigned(checked);
    }

    useEffect(() => {
        if (!selectedItems || !selectedItems.length) {
            setManageRevenueButtonDisabled(true);
            return;
        }

        if (selectedItems && selectedItems.length === 1) {
            setManageRevenueButtonDisabled(false);
            return;
        }

        const existsCustomSplit = selectedItems.some(x => x.mainSplitPercentage !== x.splitPercentage);
        const haveSameSplitPercent = selectedItems.every(x => x.mainSplitPercentage === selectedItems[0]?.mainSplitPercentage);
        setManageRevenueButtonDisabled(!(haveSameSplitPercent && !existsCustomSplit));

    }, [selectedItems]);


    const deleteButtonDisabled = selectedItems && selectedItems.length === 0;

    return (
        <Box className={styles.actionsWrapper}>
            <Box className={styles.leftSideActionsWrapper}>
                <FormControl className={styles.formControl}>
                    <CustomCheckbox
                        checked={selectAllChecked}
                        onChange={handleSelectAll}
                    >
                        Select All
                    </CustomCheckbox>
                    <CustomCheckbox
                        checked={selectOnlyUnassigned}
                        onChange={handleSelectOnlyUnassigned}
                    >
                        Show only &#60;100% Assigned Revenue
                    </CustomCheckbox>
                </FormControl>
                <SortOptions
                    options={POPOVER_OPTIONS}
                    selectedSortOption={selectedOption}
                    setSelectedSortOption={setSelectedOption}
                    fetchRevenueSplits={fetchRevenueSplits}
                />
            </Box>
            <Box className={styles.rightSideActionsWrapper}>
                {/* <BrandButton 
                    disabled={isButtonDisabled}
                    onClick={handleShowManageModal}
                >
                    View Summary
                </BrandButton> */}
                <BrandPopover
                    popoverDataFormated={
                        <TempBrandButton
                            disabled={manageRevenueButtonDisabled}
                            onClick={handleShowManageModal}
                        >
                            Manage Revenue
                        </TempBrandButton>
                    }
                    popoverData={MANAGE_REVENUE_POPOVER_TEXT}
                    popoverMaxWidth={MANAGE_REVENUE_POPOVER_WIDTH}
                    hidden={!manageRevenueButtonDisabled}
                />
                <TempBrandDeleteButton
                    className={styles.deleteBtn}
                    disabled={deleteButtonDisabled}
                    onClick={handleShowDeleteModal}
                >
                    Delete
                </TempBrandDeleteButton>
            </Box>
        </Box>
    )
}

export default ActionsWrapper;