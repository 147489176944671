import React, { useState, useEffect } from 'react';
import { getData, postData } from '../../utils/FetchUtils'
import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SecondaryBrandButton } from '../../CoreComponents/BrandButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import AlbumIcon from '@material-ui/icons/Album';
import PencilIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import { useStoreContext } from '../../../store/Store';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrandAlert } from '../../CoreComponents/BrandAlert';
import ListHover from './ListHover';
import { reportTypeIds } from '../../../constants/reportConstants';
import { BrandTooltip } from '../../CoreComponents/BrandTooltip';

const useSongStyles = makeStyles((theme) => ({
    songDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(3)
    },
    img: {
        width: theme.spacing(28),
        height: theme.spacing(28),
        borderRadius: theme.spacing(1)
    },
    songDataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    songDataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    songDataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    songDataRowLabel: {
        color: theme.palette.primary.lightGray,
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    songDataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
    },
    verifyAndCoverArt: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
            fontWeight: 400,
            marginTop: '10px'
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    icons: {
        marginRight: '6px',
    },
    pencilIcon: {
        marginLeft: '8px',
        color: theme.palette.primary.lightGrayBorder,
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`
        }
    },
    checkIcon: {
        color: theme.palette.primary.lightGrayBorder,
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            cursor: 'pointer'
        }
    },
    autoCompleteContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    autoCompleteElement: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.primary.border}`,
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: `1px solid ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border,
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
            width: 180
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },
    },
    displayNameContainer: {
        display: 'flex',
        alignItems: 'end',
        wordBreak: 'break-all'
    },
    additionalListNumber: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(0.5)
    },
}));

function Overview({ details, setDisplayConnectSong, setSelectedSongToConnect,reportingCompanies }) {
    const styles = useSongStyles();
    const [state, setState] = useStoreContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElLabelDistributor, setAnchorElLabelDistributor] = useState(null);
    const [anchorElMasterPro, setAnchorElMasterPro] = useState(null);
    const [anchorElCompositionPros, setAnchorElCompositionPros] = useState(null);
    const [anchorElPublisherAdministrators, setAnchorElPublisherAdministrators] = useState(null);
    const [alert, setAlert] = useState('');
    const [parsedAccountHolders, setParsedAccountHolders] = useState([]);
    const [displayNameAutocompleteVisibility, setDisplayNameAutocompleteVisibility] = useState(false);
    const [namesWithCategory, setNamesWithCategory] = useState([]);
    const TITLE_STRINGS_MAX_LENGTH = 80;
    const SONG_DISPLAY_NAME_MAX_LENGTH = 255;


    useEffect(() => {
        setParsedAccountHolders(details.accountHolderName);
        setState(state => ({ ...state, displayName: details.displayName }));
    }, [])

    const handlePopoverOpen = (event,setFunction) => {
        setFunction(event.currentTarget);
    };

    const handlePopoverClose = (setFunction) => {
        setFunction(null);
    };

    const handleChangeDisplayName = (e) => {
        setState(state => ({ ...state, displayName: e.target.value }));
    };

    const handleDisplayNamePencilIconClick = () => {
        setDisplayNameAutocompleteVisibility(true);
    };

    const open = Boolean(anchorEl);
    const openLabelDistributor = Boolean(anchorElLabelDistributor);
    const openMasterPro = Boolean(anchorElMasterPro);
    const openCompositionPros = Boolean(anchorElCompositionPros);
    const openPublisherAdministrators = Boolean(anchorElPublisherAdministrators);


    const updateDisplayName = () => {
        const displayName = state.displayName?.trim();
    
        postData(process.env.REACT_APP_SERVER_HOST + '/api/song/update-songs-display-name-by-user', {
            songId: details.id,
            displayName: displayName,
        })
            .then((data) => {
                setState((state) => {
                    const updatedPortfolioItems = state.portfolioItems.items.map((item) => 
                        item.id === details.id ? { ...item, displayName: displayName } : item
                    );
    
                    return {
                        ...state,
                        displayName: displayName,
                        portfolioItems: { ...state.portfolioItems, items: updatedPortfolioItems },
                        alertSettings: {
                            ...state.alertSettings,
                            open: true,
                            severity: 'success',
                            text: 'Success!',
                        },
                    };
                });
    
                details.displayName = displayName;
            })
            .catch((err) => {
                setState((state) => ({
                    ...state,
                    alertSettings: {
                        ...state.alertSettings,
                        open: true,
                        severity: 'error',
                        text: `Display name shouldn\'t be larger than ${SONG_DISPLAY_NAME_MAX_LENGTH} characters.`,
                    },
                }));
            })
            .finally(() => {
                setDisplayNameAutocompleteVisibility(false);
            });
    };
    

    const options = namesWithCategory.map((option) => {
        const subHeader = option.category;
        return {
            subHeader: /[0-9]/.test(subHeader) ? '0-9' : subHeader,
            ...option,
        };
    });

    useEffect(() => {
        postData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-options-display-names?songId=${details.id}`)
            .then(res => setNamesWithCategory(res));

    }, []);

    useEffect(() => {
        if (alert !== '') {
            setTimeout(() => {
                setAlert('');
            }, "4000")
        }
    }, [alert])



    const getReportingCompaniesByType = (typeId) =>{
        if(!reportingCompanies){
            return [];
        }

        const filteredCompanies = reportingCompanies.reduce((acc,company) => {
            if(company.reportType === String(typeId)){
                acc.push(company.name)
            }
            return acc
        },[]);
        return filteredCompanies
    }

    return (
        <>
            <div className={styles.songDataContainer}>
                <div className={styles.verifyAndCoverArt}>
                    {details.coverArt ?
                        <img src={details.coverArt} alt={''} className={styles.img}></img> //FROM SERVER
                        :
                        <AlbumIcon className={styles.img} />
                    }
                    <SecondaryBrandButton
                        color='primary'
                        variant='text'
                        startIcon={details.spotifySongId ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        onClick={e => {
                            if(!details.spotifySongId) {
                                e.stopPropagation();
                                setAlert('');
                                setDisplayConnectSong(true);
                                setSelectedSongToConnect(details);
                            } else {
                                setAlert('Song already verified!');
                            }
                        }}
                    >
                        {details.spotifySongId ?
                            'Verified'
                            :
                            'Verify'
                        }
                    </SecondaryBrandButton>
                    {alert !== '' ? <BrandAlert warning={true}>{alert}</BrandAlert> : null}
                </div>
                <div className={styles.songDataCols}>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{details.artistName || 'Unknown'}</div>
                            <div className={styles.songDataRowLabel}>Artist</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <BrandTooltip title={details.album || 'N/A'} placement="left">
                                <div className={styles.songDataRowData}>
                                    {details.album && details.album.length > TITLE_STRINGS_MAX_LENGTH
                                        ? `${details.album.substring(0, TITLE_STRINGS_MAX_LENGTH)}...`
                                        : details.album || 'N/A'}
                                </div>
                            </BrandTooltip>
                            <div className={styles.songDataRowLabel}>Album</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <BrandTooltip title={details.title || 'N/A'} placement="left">
                                {details.title && details.title.length > TITLE_STRINGS_MAX_LENGTH
                                    ? `${details.title.substring(0, TITLE_STRINGS_MAX_LENGTH)}...`
                                    : details.title || 'N/A'}
                            </BrandTooltip>
                            <div className={styles.songDataRowLabel}>Title</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                {displayNameAutocompleteVisibility ?
                                    <div className={styles.autoCompleteContainer}>
                                        <Autocomplete
                                            onChange={(event, value) => {
                                                setState(state => ({ ...state, displayName: value?.title }))
                                            }}
                                            id="grouped-demo"
                                            className={styles.autoCompleteElement}
                                            options={options}
                                            value={{ category: '', title: state.displayName }}
                                            getOptionSelected={(option, value) => option.title === value.title}
                                            groupBy={(option) => option.subHeader}
                                            getOptionLabel={(option) => option.title}
                                            renderTags={() => null}
                                            style={{ width: 270 }}
                                            renderInput={(params) => <TextField InputProps={{
                                                ...params.InputProps,
                                                inputProps: {
                                                    ...params.inputProps,
                                                    maxLength: SONG_DISPLAY_NAME_MAX_LENGTH
                                                }
                                            }} onChange={handleChangeDisplayName} label="" variant="outlined" />}
                                        />
                                        <CheckIcon
                                            onClick={updateDisplayName}
                                            className={styles.checkIcon}
                                        />
                                    </div>
                                    :
                                    <div className={styles.displayNameContainer}>
                                        <BrandTooltip title={state.displayName || 'N/A'} placement="left">
                                            {state.displayName && state.displayName.length > TITLE_STRINGS_MAX_LENGTH
                                                ? `${state.displayName.substring(0, TITLE_STRINGS_MAX_LENGTH)}...`
                                                : state.displayName || 'N/A'}
                                        </BrandTooltip>
                                        <PencilIcon onClick={handleDisplayNamePencilIconClick} className={styles.pencilIcon} />
                                    </div>
                                }
                            </div>
                            <div className={styles.songDataRowLabel}>Record display name</div>
                        </div>
                    </div>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <ListHover 
                                styles={styles}
                                open={open} 
                                handlePopoverOpen={(e)=> handlePopoverOpen(e,setAnchorEl)}
                                handlePopoverClose={() => handlePopoverClose(setAnchorEl)}
                                anchorEl={anchorEl}
                                items={parsedAccountHolders}
                                />
                            <div className={styles.songDataRowLabel}>Account Holder(s)</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{details.releaseDate ? details.releaseDate.split("T")[0] : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Released</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{details.writers || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Songwriters</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{details.producers || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Producers</div>
                        </div>
                    </div>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                <ListHover 
                                    styles={styles}
                                    open={openLabelDistributor} 
                                    handlePopoverOpen={(e) => handlePopoverOpen(e,setAnchorElLabelDistributor)}
                                    handlePopoverClose={() => handlePopoverClose(setAnchorElLabelDistributor)}
                                    anchorEl={anchorElLabelDistributor}
                                    items={getReportingCompaniesByType(reportTypeIds.LABEL_DISTRIBUTOR_REVENUE)}
                                />
                            </div> 
                            <div className={styles.songDataRowLabel}>Label/Distributor</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                    <ListHover 
                                        styles={styles}
                                        open={openMasterPro} 
                                        handlePopoverOpen={(e) => handlePopoverOpen(e,setAnchorElMasterPro)}
                                        handlePopoverClose={() => handlePopoverClose(setAnchorElMasterPro)}
                                        anchorEl={anchorElMasterPro}
                                        items={getReportingCompaniesByType(reportTypeIds.MASTER_PERFORMANCE_REVENUE)}
                                    />
                                </div> 
                            <div className={styles.songDataRowLabel}>Master Pro</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                <ListHover 
                                    styles={styles}
                                    open={openCompositionPros} 
                                    handlePopoverOpen={(e) => handlePopoverOpen(e,setAnchorElCompositionPros)}
                                    handlePopoverClose={() => handlePopoverClose(setAnchorElCompositionPros)}
                                    anchorEl={anchorElCompositionPros}
                                    items={getReportingCompaniesByType(reportTypeIds.PRO_REVENUE)}
                                />
                            </div>  
                            <div className={styles.songDataRowLabel}>Composition PRO</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                <ListHover 
                                    styles={styles}
                                    open={openPublisherAdministrators} 
                                    handlePopoverOpen={(e) => handlePopoverOpen(e,setAnchorElPublisherAdministrators)}
                                    handlePopoverClose={() => handlePopoverClose(setAnchorElPublisherAdministrators)}
                                    anchorEl={anchorElPublisherAdministrators}
                                    items={getReportingCompaniesByType(reportTypeIds.PUBLISHER_ADMIN_REVENUE)}
                                />
                            </div>  
                            <div className={styles.songDataRowLabel}>Publisher/Administrator</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Overview;