import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TablePagination, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { ButtonSize } from '../../../constants/buttonConstants';
import useAbortableFetch from '../../../hooks/useAbortableFetch';
import { useSnackbarAlert } from '../../../hooks/useSnackbarAlert';
import { useStoreContext } from '../../../store/Store';
import { TempBrandButton, TempSecondaryBrandButton } from '../../CoreComponents/BrandButton';
import { BrandModal } from '../../CoreComponents/BrandModal';
import BrandTable from '../../CoreComponents/BrandTable';
import TablePaginationActions from '../../CoreComponents/TablePaginationActions';
import { userAccessByType } from '../../utils/AccessUtils';
import { formatDate, parseDateToISODateFormat } from '../../utils/DateUtils';
import { SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE, SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../../utils/PaginationUtils';
import ActionsRowMenu from './ActionsRowMenu';

const sharedTableRowStyles = (theme) => ({
    backgroundColor: theme.palette.background.darkNuanceVersion4,
    border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    height: '55px',
    paddingTop: '16px',
    paddingLeft: '16px',
    marginTop: '7px',
    marginBottom: '13px'
});

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiTableCell-root': {
            padding: '0px'
        },
        '& .MuiTableCell-head': {
            padding: '16px',
            marginBottom: '5px'
        },
        '& .MuiTableRow-root': {
            font: 'normal normal medium 14px/17px Roboto',
        },
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
    },
    rowItemFirst: {
        ...sharedTableRowStyles(theme),
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
        borderRight: 'none',
    },
    rowItemMiddle: {
        ...sharedTableRowStyles(theme),
        borderRight: 'none',
        borderLeft: 'none',
    },
    rowItemLast: {
        ...sharedTableRowStyles(theme),
        borderBottomRightRadius: '6px',
        borderTopRightRadius: '6px',
        borderLeft: 'none',
        paddingRight: '17px',
        paddingTop: '0px',
        textAlign: 'right'
    },
    status: {
        font: 'normal normal medium 17px/28px Roboto',
        letterSpacing: '0px',
        color: theme.palette.text.greenNuanceVersion3,
    },
    reportingPeriod: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: '18px',
        marginLeft: '10px',
    },
    reportingPopper: {
        zIndex: 1,
        border: `1px solid ${theme.palette.primary.border}`,
        borderRadius: '10px',
        marginTop: '10px',
    },
    menuItem: {
        padding: '8px',
        font: 'normal normal medium 14px/21px Roboto',
        letterSpacing: '0px',
        textAlign: 'left',
        width: '150px',
        '& > :first-child': {
            marginRight: theme.spacing(1),
        },
    },
    deleteText: {
        color: theme.palette.primary.delete,
    },
    checkboxButton: {
        '& .MuiButtonBase-root': {
            backgroundColor: 'unset',
            padding: 0
        }
    },
    modalActions: {
        marginTop: '55px',
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        '& button:last-child': {
            width: '200px',
        }
    },
    modalTextWrapper: {
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        maxWidth: '95%',
        '& h5': {
            fontWeight: 'bold'
        }
    },
    modalWarningText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.primary.attention
    },
    publishedCheckbox: {
        '& .MuiButton-label': {
            color: `${theme.palette.primary.main} !important`
        }
    },
    alertBtn: {
        backgroundColor: theme.palette.primary.delete,
        '&:hover': {
            backgroundColor: theme.palette.primary.delete
        }
    }
}));

const PublishConfirmationModal = ({ openModal, onClose, handleConfirm, selectedRow }) => {
    const styles = useStyles();
    return (
        <BrandModal
            open={openModal}
            onClose={onClose}
        >
            <div style={{ maxWidth: '600px' }}>
                <div className={styles.modalTextWrapper}>
                    <Typography variant='h5'>Notice</Typography>
                    <Typography variant='body1'>
                        By clicking on this action the generated report PDF will be visible to all users with the attached Account holder:<br />
                        {selectedRow?.payeeAccountHolder}
                    </Typography>
                    <div className={styles.modalWarningText}>
                        <ReportProblemOutlinedIcon />
                        <Typography variant='body1'>Are you sure you want to publish the report?</Typography>
                    </div>
                </div>
                <div className={styles.modalActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        onClick={onClose}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            handleConfirm(selectedRow?.id);
                            onClose();
                        }}
                    >
                        Change Status
                    </TempBrandButton>
                </div>
            </div>
        </BrandModal>
    );
}

const WarningConfirmationModal = ({ mainText, buttonText, onClose, handleConfirm, selectedRow }) => {
    const styles = useStyles();
    return (
        <BrandModal
            open={true}
            onClose={onClose}
        >
            <div style={{ width: '600px' }}>
                <div className={styles.modalTextWrapper}>
                    <Typography variant='h5'>Warning</Typography>
                    <Typography variant='body1'>
                        {mainText}

                    </Typography>
                    <div className={styles.modalWarningText}>
                        <ReportProblemOutlinedIcon />
                        <Typography variant='body1'>This action cannot be undone.</Typography>
                    </div>
                </div>
                <div className={styles.modalActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        onClick={onClose}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        className={styles.alertBtn}
                        size={ButtonSize.DEFAULT}
                        onClick={() => {
                            handleConfirm(selectedRow?.id);
                            onClose();
                        }}
                    >
                        {buttonText}
                    </TempBrandButton>
                </div>
            </div>
        </BrandModal>
    );
}

const RoyaltyStatementsDataTable = ({
    startDate,
    endDate,
    selectedArtistAccountHolders,
    selectedLabelAccountHolders,
    selectedStatus,
    statusesProps
}) => {

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();
    const [state] = useStoreContext();
    const { showSuccessAlert, showErrorAlert } = useSnackbarAlert();

    const isAdmin = state.user && state.user.userType === userAccessByType.USER_TYPE_ADMIN_ACCESS;
    const [adminOnlyRows] = useState(['createdOn', 'publishDate', 'invalidationDate', 'status', 'paymentStatus']);

    const [headCells] = useState([
        {
            id: 'payeeAccountHolder',
            label: "Artist's Account holder",
            width: '15%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemFirst}>
                        {rowData.payeeAccountHolder || 'N/A'}
                    </div>
                )
            }
        },
        {
            id: 'payorAccountHolder',
            label: "Label's Account holder",
            width: '15%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.payorAccountHolder || 'N/A'}
                    </div>
                )
            }
        },
        {
            id: 'reportingPeriod',
            label: 'Reporting Period',
            width: '12.5%',
            notSortable: true,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        <div className={styles.reportingPeriod}>
                            {formatDate(rowData.startDate)} - {formatDate(rowData.endDate)}
                        </div>
                    </div>
                );
            },
        },
        {
            id: 'createdOn',
            label: 'Generated On',
            width: '10%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.createdOn.split('T')[0]}
                    </div>
                );
            }
        },
        {
            id: 'publishDate',
            label: 'Publish Date',
            width: '10%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.publishDate?.split('T')[0] || 'N/A'}
                    </div>
                );
            }
        },
        {
            id: 'invalidationDate',
            label: 'Invalidation Date',
            width: '10%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.invalidationDate?.split('T')[0] || 'N/A'}
                    </div>
                );
            }
        },
        {
            id: 'status',
            label: 'Report Status',
            width: '8.5%',
            CellRender: ({ rowData }) => {
                const statusId = rowData.status;
                return (
                    <div className={clsx(styles.rowItemMiddle, statusesProps[statusId].style)}>
                        {statusesProps[statusId]?.name}
                    </div>
                );
            }
        },
        // { TODO Implement as new separate DB column
        //     id: 'paymentStatus',
        //     label: 'Payment Status',
        //     width: '12.5%',
        //     CellRender: ({ rowData }) => {
        //         const disabled = rowData.status != royaltyStatementStatus.Pending;
        //         const checked = rowData.status == royaltyStatementStatus.Published || rowData.status == royaltyStatementStatus.Invalidated;
        //         return (
        //             <div className={clsx(styles.rowItemMiddle, styles.checkboxButton, rowData.status == royaltyStatementStatus.Published ? styles.publishedCheckbox : null)}>
        //                 <SecondaryBrandButton
        //                     variant='text'
        //                     key={rowData.id}
        //                     disabled={disabled}
        //                     startIcon={checked ? <CheckBoxIcon disabled={disabled} /> : <CheckBoxOutlineBlankIcon disabled={disabled} />}
        //                     onClick={e => {
        //                         e.stopPropagation();
        //                         setShowPublishConfirmationModal(true)
        //                         setSelectedRow(rowData);
        //                     }}
        //                 >
        //                     {checked ? 'Paid' : 'Use for payment'}
        //                 </SecondaryBrandButton >
        //             </div>
        //         );
        //     }
        // },
        {
            id: 'actions',
            label: '',
            notSortable: true,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemLast}>
                        <ActionsRowMenu
                            rowData={rowData}
                            handleDownload={handleDownload}
                            onDelete={() => {
                                setShowDeleteConfirmationModal(true);
                                setSelectedRow(rowData);
                            }}
                            onInvalidate={() => {
                                setShowInvalidateConfirmationModal(true);
                                setSelectedRow(rowData);
                            }}
                            onPublish={() => {
                                setShowPublishConfirmationModal(true);
                                setSelectedRow(rowData);
                            }}
                        />
                    </div>
                );
            }
        }
    ].filter((headCell) => isAdmin || adminOnlyRows.indexOf(headCell.id) === -1));

    const [items, setItems] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE);
    const [rowsPerPageOptions] = useState(SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS);
    const [sortBy, setSortBy] = useState('createdOn');
    const [sortType, setSortType] = useState('DESC');
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showPublishConfirmationModal, setShowPublishConfirmationModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showInvalidateConfirmationModal, setShowInvalidateConfirmationModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        setPage(0);
    }, [startDate, endDate, selectedArtistAccountHolders, selectedLabelAccountHolders, selectedStatus]);

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page]);

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, selectedArtistAccountHolders, selectedLabelAccountHolders, selectedStatus, offset, rowsPerPage, sortType, sortBy])

    const fetchData = async () => {
        setLoading(true);
        const body = {
            payorIds: selectedLabelAccountHolders.map(ah => ah.id),
            payeeIds: selectedArtistAccountHolders.map(ah => ah.id),
            sort: sortBy,
            type: sortType,
        };
        if (startDate && endDate) {
            body.startDate = parseDateToISODateFormat(startDate);
            body.endDate = parseDateToISODateFormat(endDate);
        }
        if (selectedStatus > 0)
            body.status = selectedStatus;
        try {
            const urlPath = isAdmin ?  '/api/royalty-statement' : '/api/royalty-statement/user-reports';
            const fetchedData = await abortableFetch('POST',
                urlPath, {
                body,
                query: {
                    offset,
                    limit: rowsPerPage
                }
            });

            setItems(fetchedData?.royaltyStatements || []);
            setItemsCount(fetchedData?.totalCount || 0);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleDownload = (id) => {
        const url = process.env.REACT_APP_SERVER_HOST + `/api/royalty-statement/get-pdf?id=${id}`;
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const publishRoyaltyStatement = async (royaltyStatementId) => {
        try {
            const result = await abortableFetch('POST',
                '/api/royalty-statement/publish', {
                body: {
                    royaltyStatementId
                }
            });

            showSuccessAlert('Report published successfully.');
            fetchData();
        } catch (error) {
            console.error(error);
            showErrorAlert('There was a problem publishing the report. Please try again.');
        }
    }

    const deleteRoyaltyStatement = async (id) => {
        try {
            const result = await abortableFetch('DELETE',
                '/api/royalty-statement', {
                body: {
                    id
                }
            });

            showSuccessAlert('Report successfully deleted.');
            fetchData();
        } catch (error) {
            console.error(error);
            showErrorAlert('There was a problem while deleting the report. Please try again.');
        }
    }

    const invalidateRoyaltyStatement = async (id) => {
        try {
            const result = await abortableFetch('POST',
                '/api/royalty-statement/invalidate', {
                body: {
                    id
                }
            });

            showSuccessAlert('Status successfully changed.');
            fetchData();
        } catch (error) {
            console.error(error);
            showErrorAlert('There was a problem while changing the status. Please try again.');
        }
    }

    return (
        <div className={styles.container}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        rows={items}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        rowIsClickable={false}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={itemsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}

            <PublishConfirmationModal
                openModal={showPublishConfirmationModal}
                handleConfirm={publishRoyaltyStatement}
                onClose={() => setShowPublishConfirmationModal(false)}
                selectedRow={selectedRow}
            />
            {showInvalidateConfirmationModal &&
                <WarningConfirmationModal
                    mainText={"Are you sure you want to change the status of report to Invalidated?"}
                    buttonText={"Change Status"}
                    handleConfirm={invalidateRoyaltyStatement}
                    onClose={() => setShowInvalidateConfirmationModal(false)}
                    selectedRow={selectedRow}
                />
            }
            {showDeleteConfirmationModal &&
                <WarningConfirmationModal
                    mainText={"Are you sure you want to delete this report?"}
                    buttonText={"Delete Report"}
                    handleConfirm={deleteRoyaltyStatement}
                    onClose={() => setShowDeleteConfirmationModal(false)}
                    selectedRow={selectedRow}
                />
            }
        </div>
    )
}

export default RoyaltyStatementsDataTable