import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { postData } from '../utils/FetchUtils';
import { useStoreContext } from '../../store/Store';
import SongsList from './SongsList';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '30px',
    },
    accountHolderSubTitle: {
        fontSize: '14px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    }
}));

const getUniqueSongs = (validationRows, reportTemplate, customDatePeriod) => {
    const uniqueSongs = {};

    // TODO remove hardcoded indexes for columns
    for (let i = 0; i < validationRows.length; i++) {
        const title = validationRows[i].cells['0'].text ?? '';
        const artist = validationRows[i].cells['1'].text === 'No Data' ? '' : (validationRows[i].cells['1'].text ?? '');
        const isrc = validationRows[i].cells[customDatePeriod ? '3' : '5'].text === 'No Data' ? '' : (validationRows[i].cells[customDatePeriod ? '3' : '5'].text ?? '');
        const upc = validationRows[i].cells[customDatePeriod ? '4' : '6'].text === 'No Data' ? '' : (validationRows[i].cells[customDatePeriod ? '4' : '6'].text ?? '');
        const externalId = validationRows[i].cells[customDatePeriod ? '5' : '7'].text === 'No Data' ? '' : (validationRows[i].cells[customDatePeriod ? '5' : '7'].text ?? '');

        const key = `${title}-${artist}-${upc}-${isrc}-${externalId}`;
        if (uniqueSongs[key]) {
            if (!uniqueSongs[key].isrc && isrc) {
                uniqueSongs[key].isrc = isrc;
            }
            if (!uniqueSongs[key].upc && upc) {
                uniqueSongs[key].upc = upc;
            }
        } else {
            uniqueSongs[key] = {
                title: title,
                upc: upc,
                isrc: isrc,
                artist: artist,
                externalId: externalId,
                foundSongId: '',
                foundArtistId: '',
                foundTitle: '',
                foundArtist: '',
                foundIsrc: '',
                foundAlbum: '',
                foundCoverArt: ''
            };
        }
    }

    return uniqueSongs;
}

export default function Songs({
    validationRows,
    setLoadingSongsStep,
    mappingTemplate,
    songsMapKeys,
    setSongsMapKeys,
    setPartialSongsMapKeys,
    reportingCompany,
    accountHolder,
    setExternalISRCs,
    setExternalUPCs,
    fileMetadata,
}) {
    const [state, setState] = useStoreContext();
    const styles = useStyles();

    useEffect(() => {
        if (state.findSongsInDb) {
            setLoadingSongsStep(true);
            const uniqueSongs = getUniqueSongs(validationRows, mappingTemplate, fileMetadata.customDatePeriod);

            postData(process.env.REACT_APP_SERVER_HOST + "/api/song/find-matching-songs", { uniqueSongs: uniqueSongs, reportingCompany })
                .then(data => {
                    setState(state => (state.findSongsInDb = false, { ...state }));
                    setState(state => (state.songsMap = data.matchingResultByLevels.fullMatch, { ...state }));
                    setState(state => (state.partialSongsMap = data.matchingResultByLevels.partialMatch, { ...state }));
                    setLoadingSongsStep(false);
                    setSongsMapKeys(Object.keys(data.matchingResultByLevels.fullMatch));
                    setPartialSongsMapKeys(Object.keys(data.matchingResultByLevels.partialMatch));

                    // A store for the ISRCs of the songs matched to songs in the DB 

                    const songExternalISRCs = {};
                    const songExternalUPCs = {};

                    const allSongs = [...Object.values(data.matchingResultByLevels.fullMatch), ...Object.values(data.matchingResultByLevels.partialMatch)];
                    for (const song of allSongs) {
                        const {
                            upc,
                            isrc,
                            found,
                            foundSongId
                        } = song;

                        if (!found) continue;

                        if (!songExternalISRCs[foundSongId]) songExternalISRCs[foundSongId] = [];
                        if (!songExternalUPCs[foundSongId]) songExternalUPCs[foundSongId] = [];

                        if (isrc) songExternalISRCs[foundSongId].push(isrc);
                        if (upc) songExternalUPCs[foundSongId].push(upc);
                    }

                    setExternalISRCs(songExternalISRCs);
                    setExternalUPCs(songExternalUPCs);
                }, error => {
                    setState(state => (state.findSongsInDb = true, { ...state }));
                    setLoadingSongsStep(false);
                });
        }
    }, []);

    const setExternalData = (setExternalFunction, value, foundSongId) => {
        if (!value) return;

        setExternalFunction((prev) => ({
            ...prev,
            [foundSongId]: [...(prev[foundSongId] || []), value]
        }));
    }

    const onSelectedSong = (song, songMapKey) => {
        const actualSong = state.songsMap[songMapKey];

        setExternalData(setExternalISRCs, actualSong.isrc, song.foundSongId);
        setExternalData(setExternalUPCs, actualSong.upc, song.foundSongId);

        const tempSongsMap = state.songsMap;
        tempSongsMap[songMapKey] = {
            ...tempSongsMap[songMapKey],
            ...song
        }

        setState(state => (state.songsMap = { ...tempSongsMap }, { ...state }));
    };

    return (
        <div>
            <div className={styles.title}>4. Songs - {fileMetadata.path}</div>
            <div className={styles.accountHolderSubTitle}>Current Account Holder: {accountHolder.name}</div>
            <SongsList
                songsMapKeys={songsMapKeys}
                onSelectedSong={onSelectedSong}
                setExternalISRCs={setExternalISRCs}
                setExternalUPCs={setExternalUPCs}
                accountHolder={accountHolder}
            />
        </div>
    );
}