import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@material-ui/core/IconButton';
import { InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BrandAlert } from './BrandAlert'
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { BrandTooltipWithIcon } from './BrandTooltip';

// magic...
const InternalInput = React.forwardRef(function Input(props, ref) {
    const {
        disableUnderline,
        classes = {},
        fullWidth = false,
        inputComponent = 'input',
        multiline = false,
        type = 'text',
        disabled,
        ...other
    } = props;

    return (
        <InputBase
            classes={{
                ...classes,
                root: clsx(classes.root, {
                    [classes.underline]: !disableUnderline,
                }),
                underline: null,
            }}
            fullWidth={fullWidth}
            inputComponent={inputComponent}
            multiline={multiline}
            ref={ref}
            type={type}
            disabled={disabled}
            {...other}
        />
    );
});

export const BrandInput = withStyles(theme => ({
    root: {
        position: 'relative',
        boxSizing: 'content-box',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1),
        // font: ...,
        // letterSpacing: ...,
    },
    label: {
        margin: theme.spacing(0, 'auto', 1, 0),
    },
    input: {
        height: theme.spacing(5),
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: theme.spacing(2.5),
        boxSizing: 'border-box',
        padding: theme.spacing(1, 2),
        background: 'none',
        color: theme.palette.primary.contrastText,
        // font: ...,
        // letterSpacing: ...,
    },
    error: {
        borderColor: theme.palette.primary.alert
    },
    label_wrapper:{
        marginBottom: '10px',
        textAlign:'start',
        height: '28px',
    },
    required: {
        color: theme.palette.primary.alert
    },
    disabled:{
        color:`${theme.palette.primary.darkGray} !important`
    }
}))(({ classes, ...props }) => {
    const {
        variant,
        label,
        InputLabelProps,
        inputProps,
        InputProps,
        $value,
        validator,
        validatorPassed,
        maxLength,
        required,
        disabled,
        iconWithTooltip,
        tooltipMessage,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);

    const activateValidator = (value) => {
        if (validator) {
            setValidationErr(validator(value))
            if (validatorPassed) {
                if (validator(value).length) {
                    validatorPassed[1](false);
                } else {
                    validatorPassed[1](true);
                }
            }
        }
    }

    const onChange = (e) => {
        const value = e.target.value;
        activateValidator(value);
        $value[1](value);
    }

    return (label ?
        <label className={classes.root} {...InputLabelProps}>
            
            <div className={classes.label_wrapper}>
                <span className={clsx(classes.label,disabled ? classes.disabled: '' )}>{label}</span>
                {required ? <span className={classes.required}>*</span> : ''}
                    {iconWithTooltip && <BrandTooltipWithIcon title={tooltipMessage} />}
            </div>
            {$value ?
                <InternalInput
                    inputProps={{
                        maxLength: maxLength,
                        ...inputProps
                    }}
                    {...InputProps}
                    {...other}
                    value={$value[0]}
                    onChange={onChange}
                    className={clsx(classes.input, 
                        validationErr.length ? classes.error : null,
                        disabled ? classes.disabled : ''
                        )}
                    disabled={disabled}
                />
                :
                <InternalInput
                    inputProps={{
                        maxLength: maxLength,
                        ...inputProps
                    }}
                    {...InputProps}
                    {...other}
                    className={classes.input}
                />
            }
            {validationErr.map(x => <BrandAlert key={x}>{x}</BrandAlert>)}
        </label>
        : $value ?
            <InternalInput
                inputProps={{
                    maxLength: maxLength,
                    ...inputProps
                }}
                {...InputProps}
                {...other}
                value={$value[0]}
                onChange={onChange}
                className={clsx(classes.input, validationErr.length ? classes.error : null)}
            />
            :
            <InternalInput
                inputProps={{
                    maxLength: maxLength,
                    ...inputProps
                }}
                {...InputProps}
                {...other}
                className={classes.input}
            />
    );
});

const useBrandPasswordInputStyles = makeStyles((theme) => ({
    icon: {
        position: 'absolute',
        right: theme.spacing(1),
        padding: 0,
        color: theme.palette.text.hint
    },
}));

export function BrandPasswordInput(props) {
    const styles = useBrandPasswordInputStyles();
    const [show, setShow] = useState(false);
    const [inputType, setInputType] = useState('password')

    useEffect(() => {
        if (show) {
            setInputType('text')
        } else {
            setInputType('password')
        }
    }, [show]);

    function handleClickShowPassword(e) {
        setShow(!show);
    }

    return (
        <BrandInput type={inputType}
            InputProps={{
                endAdornment: (
                    <IconButton onClick={handleClickShowPassword} className={styles.icon}>
                        {show ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
            }}
            {...props}
        />
    );
}


export function BrandSearchInput(props) {
    return (
        <BrandInput
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"><SearchIcon style={{ color: '#707070' }} /></InputAdornment>
                )
            }}
            {...props}
        />
    );
}