import React, { forwardRef, useState, useEffect } from 'react';
import { OutlinedInput, Tooltip } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandInput } from './BrandInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';

export const BrandMenuItem = forwardRef((props, ref) =>
    <MenuItem innerRef={ref} {...props} />)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
            width: 500,
        },
    },
};

const useMultiSelectStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '8px',
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    input: {
        '& .MuiOutlinedInput-notchedOutline': {
            transition: 'border-color 0.4s ease',
        },
    },
    filled: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
    },
    unfilled: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.attention,
        },
    },
    unfilledMandatory: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.alert,
        },
    },
    neither: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'magenta',
        },
    },
    menuItem: {
        opacity: 0,                     
        animation: '$fadeIn 1s forwards',
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    arrowDropDownIcon: {
        marginRight: '5px',
        marginLeft: '2px',
        color: 'white',
    },
}));

export function BrandMultiSelect({ value, onChange, autodetected, templateCol, autoDetectedTrigger, mappingColumns, status }) {
    const styles = useMultiSelectStyles();
    const [chips, setChips] = useState([]);
    const MAX_COLUMN_LENGTH = 18;

    const inputClass = clsx(styles.input, {
        [styles.filled]:            status === 'filled',
        [styles.unfilled]:          status === 'unfilled',
        [styles.unfilledMandatory]: status === 'unfilledMandatory',
        [styles.unknown]:           status === 'neither',
    });

    const iconStyle = {
        color: 'white',
        marginRight: '2px',
    }

    const handleChange = (event) => {
        const value = event.target.value;
        setChips([value]);
        onChange([value]);
    };

    const handleDelete = (e, value) => {
        setChips(chips.filter(chip => chip !== value));
        onChange(chips.filter(chip => chip !== value));
    };

    useEffect(() => {
        if (Object.keys(autodetected).length > 0 && autodetected[templateCol]) {
            setChips(autodetected[templateCol]);
        }
        else if (Object.keys(autodetected).length === 0) {
            setChips([]);
        }
    }, [autoDetectedTrigger, autodetected]);

    return (
        <FormControl classes={{ root: styles.root }}>
            <Select
                value={chips.length === 0 ? 'Select Option' : chips[0]}
                onChange={handleChange}
                input={
                    <OutlinedInput
                        id="select-multiple-chip"
                        className={inputClass}
                    />
                }
                renderValue={(selected) => {                    
                    let columnName = selected
                    if (columnName.length > MAX_COLUMN_LENGTH) {
                        columnName = columnName.slice(0, MAX_COLUMN_LENGTH - 1) + '...';
                    }
                    
                    return (
                        <Box key={selected} className={styles.box}>
                          <Tooltip title={selected}>
                            <Box>{columnName}</Box>
                          </Tooltip>
                          <Box
                            onMouseDown={(ev) => ev.stopPropagation()}
                            onClick={(event) => handleDelete(event, selected)}
                          >
                            <ClearIcon fontSize="small"/>
                          </Box>
                        </Box>
                    )}}
                IconComponent={(props) => (
                    <ArrowDropDownIcon fontSize='medium' style={iconStyle} {...props} />
                )}
                MenuProps={MenuProps}
            >
                {value.map((x) => {
                    let columns = (mappingColumns[x.id] || []).join(', ');
                    if (columns.length > MAX_COLUMN_LENGTH) {
                        columns = columns.slice(0, MAX_COLUMN_LENGTH - 1) + '...';
                    }
                    return (
                        <BrandMenuItem key={x.name} value={x.name} className={styles.menuItem}>
                            <Checkbox color='primary' checked={chips.includes(x.name)} />
                            <ListItemText primary={`${x.name}${columns ? ` (e.g. ${columns})` : ''}`} />
                        </BrandMenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

const useSelectStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    arrow: {
        right: theme.spacing(0.5),
        position: 'absolute',
        cursor: 'pointer',
    }
}));

export function BrandSelect({ label, value, onChange, onChangeWithNotify, children, ...props }) {
    const styles = useSelectStyles();
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        onChange(e.target.value)
    };

    return (props.$value ?
        <Select
            value={props.$value[0]}
            onChange={e => props.$value[1](e.target.value)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={(props) => <ExpandMoreIcon fontSize='large' {...props} className={styles.arrow} onClick={() => setOpen(!open)} />}
            input={<BrandInput label={label} />}
        >
            {children}
        </Select>
        :
        <Select
            value={value}
            onChange={onChangeWithNotify ? onChangeWithNotify : handleChange}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={(props) => <ExpandMoreIcon fontSize='large' {...props} className={styles.arrow} onClick={() => setOpen(!open)} />}
            input={<BrandInput label={label} />}
        >
            {children}
        </Select>
    );
}
