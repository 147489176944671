import React, { useEffect, useRef, useState } from 'react';
import './Validation.scss';
import { makeStyles } from '@material-ui/core/styles';
import { BrandCanvasTable } from '../CoreComponents/BrandCanvasTable.js';
import { cellTextIsEmpty, updateColumnClass } from '../utils/ValidationUtils';
import { useStoreContext } from '../../store/Store';
import { useTheme } from '@material-ui/styles';
import { validateDateRange } from '../utils/DateUtils.js';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ColumnStatus } from '../../constants/UploadContants.js';
import CellErrorsModal, { ErrorModalType } from './Modals/CellErrorsModal.js';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox.js';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';


const useStyles = makeStyles((theme) => ({
    mistakes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '18px',
        marginBottom: '1em',
    },
    errorsInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    invalid: {
        color: theme.palette.primary.alert,
        fontWeight: 'bold',
    },
    pkDuplicate: {
        color: theme.palette.primary.warning,
        fontWeight: 'bold',
    },
    mandatory: {
        color: theme.palette.primary.attention,
        fontWeight: 'bold',
        marginRight: theme.spacing(2)
    },
    tableHeaderText: {
        color: theme.palette.primary.lightGray,
        margin: 0
    },
    title: {
        font: 'normal normal bold 30px/21px Roboto',
        marginBottom: theme.spacing(2),
    },
    fileName: {
        font: 'normal normal normal 24px/21px Roboto'
    },
    colHeader: {
        background: `${theme.palette.primary.lightGray} 0% 0% no-repeat padding-box`,
        opacity: 1,
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.primary.lightYellow}`,
    },
    mappingSelect: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        color: 'black',
        fontWeight: 'bold',
        width: '250.2px',
        minWidth: '250.2px',
        maxWidth: '250.2px',
        textAlign: 'center',
        backgroundColor: theme.palette.background.darkNuanceVersion2,
        border: `1px solid ${theme.palette.primary.lightGray}`,

        '&:not(last-child)': {
            borderRight: 'none',
        },
        '& .filled': {
            backgroundColor: theme.palette.primary.main,
            padding: '0.3em 0.6em'
        },
        '& .unfilledMandatory': {
            backgroundColor: theme.palette.primary.alert,
            padding: '0.3em 0.6em'
        },
        '& .wrongDataFormat': {
            backgroundColor: theme.palette.primary.warning,
            padding: '0.3em 0.6em'
        },
        '& .unfilled': {
            backgroundColor: theme.palette.primary.attention,
            padding: '0.3em 0.6em'
        },
    },
    label: {
        font: 'normal normal bold 16px/21px Roboto',
    },
    mappingTop: {
        display: 'flex',
        marginTop: '1em'
    },
    mappingTable: {
        width: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        '& .x-spreadsheet-bottombar': {
            display: 'none'
        },
        '&::-webkit-scrollbar': {
            height: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.lightGray,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.darkGray,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary.black,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
    },
    table: {
        overflow: 'hidden',
        width: '100%',
        '& .x-spreadsheet': {
            marginTop: '-49px',
            marginLeft: '-57px',
        }
    },
    wrongDataModal: {

    },
    rowsContainer: {
        maxHeight: '600px',
        overflow: 'auto'
    },
    wrongDataModalRow: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    wrongDataButton: {
        backgroundColor: theme.palette.primary.alert,
        color: theme.palette.text.black,
        fontWeight: 'bold',
        width: '150px',
        height: '42px',
        '&:hover': {
            background: theme.palette.primary.alertHover,
        }
    },
    invalidContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.alert,
        justifyContent: 'space-between',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        alignContent: 'center',
        gap: '10px',
    },
    pkDuplicateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.warning,
        justifyContent: 'space-between',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        alignContent: 'center',
        gap: '10px',
    },
    viewButton: {
        textDecoration: 'underline',
        fontWeight: 'bold',
        '&:hover': {
            cursor: 'pointer',
        }
    },

    mainContainer: {
        position: 'relative',
        width: '100%',
        height: '61%',
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        width: "40px",
        height: "40px",
        zIndex: 250,

        cursor: 'pointer',
        pointerEvents: 'auto',
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 6px 12px ${theme.palette.primary.black}`,
        borderRadius: "50%",

        display: "flex",
        justifyContent: "center",
        alignItems: 'center',

        '&:hover': {
            opacity: 0.9,
        },
    },
    leftScrollIcon: {
        left: 20,
    },
    rightScrollIcon: {
        right: 20,
    },
    tableWrapper: {
        overflow: 'auto',
        height: '100%',
    },
}));

/**
 * 1 = red style / mandatory cells are empty or wrong data type
 * 2 = orange style / wrong data type or format
 * 3 = yellow style / empty but not mandatory
 * 4 = default style / white text
 */
const CellValidationLevel = {
    Mandatory: 1,
    WrongFormat: 2,
    Empty: 3,
    Default: 4
};


function validateCell(cellData, columnMapping) {
    const requiredValidatorName = ['required'];

    if (columnMapping.required) {
        if (cellTextIsEmpty(cellData)) {
            return [CellValidationLevel.Mandatory, { [columnMapping.name]: requiredValidatorName }];
        }

        for (const validator of columnMapping.validations) {
            const isValid = validator(cellData);
            if (!isValid) {
                return [CellValidationLevel.Mandatory, { [columnMapping.name]: columnMapping.validatorName }];
            }
        }

        return [CellValidationLevel.Default];
    }

    if (cellTextIsEmpty(cellData)) {
        return [CellValidationLevel.Empty];
    }

    for (const validator of columnMapping.validations) {
        const isValid = validator(cellData);
        if (!isValid) {
            return [CellValidationLevel.WrongFormat, { [columnMapping.name]: columnMapping.validatorName }];
        }
    }
    return [CellValidationLevel.Default];
}

export function Validation({
    validationRows,
    setValidationRows,
    mandatoryCellsAreEmptyOrWrongDataType,
    setMandatoryCellsAreEmptyOrWrongDataType,
    dataMappings,
    mappingTemplate,
    retryUpload,
    fileMetadata
}) {
    const styles = useStyles();
    const theme = useTheme();
    const tableRef = useRef(null);
    const [state, setState] = useStoreContext();
    const [cellsAreWrongDataTypeOrFormat, setCellsAreWrongDataTypeOrFormat] = useState(0);
    const [cellsAreEmptyButNotMandatory, setCellsAreEmptyButNotMandatory] = useState(0);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorModalType, setErrorModalType] = useState(null);

    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(true);

    const SCROLL_AMOUNT = 250;

    const TABLE_WIDTH = mappingTemplate.length * 250 + 210;
    const DEFAULT_TABLE_HEIGHT = 582;

    const ROW_HEGIHT = 50;
    const COL_WIDTH = 250;

    const blackIcon = {
        color: 'black',
    }

    const getTableViewHeight = (numRows) => {
        return numRows * 59.4 + 140 / numRows
    }

    const [tableStyles] = useState([
        {}, // Neither
        {   // Unfilled mandatory
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.primary.alert,
        },
        {   // Wrong Data Type or Format
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.primary.warning,
        },
        {   // Unfilled but not mandatory
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.primary.attention,
        },
        {   // Filled
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.text.primary,
        }
    ]);
    
    const [tableOptions] = useState({
        mode: 'edit',
        showToolbar: false,
        showContextmenu: false,
        scrollable: true,
        autoFill: false,
        view: {
            height: () => Math.min(DEFAULT_TABLE_HEIGHT, getTableViewHeight(validationRows.length)),
            width: () => TABLE_WIDTH
        },
        row: {
            height: ROW_HEGIHT,
        },
        col: {
            len: mappingTemplate.length,
            width: COL_WIDTH,
        },
        style: {
            align: 'left',
            textwrap: true,
            font: {
                name: 'Roboto',
                size: 11,
                bold: false,
                italic: false,
            },
            border: {
                top: ['thin', theme.palette.background.mediumGray],
                bottom: ['thin', theme.palette.background.mediumGray],
                right: ['thin', theme.palette.background.mediumGray],
                left: ['thin', theme.palette.background.mediumGray],
            }
        }
    });
    const [displayRows, setDisplayRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [wrongData, setWrongData] = useState([]);
    const displayFieldsWithErrorsOnly = useState(false);

    useEffect(() => {
        validateRows(validationRows);
        const sortedRows = [...validationRows];
        sortedRows.sort((a, b) => {
            const rowDataA = Object.values(a.cells);
            const rowDataB = Object.values(b.cells);
            return rowDataB.findIndex(x => x.style === 1) - rowDataA.findIndex(x => x.style === 1);
        })

        setDisplayRows(sortedRows);
        setFilteredRows(sortedRows);

    }, []);

    useEffect(() => {
        if (!displayFieldsWithErrorsOnly[0] && displayRows.length !== 0) {
            setFilteredRows(displayRows);
        }
        else if (displayFieldsWithErrorsOnly[0]) {
            const filtered = displayRows.filter(row =>
                Object.values(row.cells).some(cell => cell.wrongCol !== '')
            )
            setFilteredRows(filtered);
        }
    }, [displayFieldsWithErrorsOnly[0]])

    useEffect(() => {
            if (!displayRows) return;
            mappingTemplate.forEach((column, index) => {
            updateColumnClass(column, ColumnStatus.UnfilledMandatory.name, true);
            updateColumnClass(column, ColumnStatus.WrongDataFormat.name, true);
            updateColumnClass(column, ColumnStatus.Unfilled.name, true);
            updateColumnClass(column, ColumnStatus.Filled.name, true);
            displayRows.forEach((row, _) => {
                const rowStyle = row?.cells[index].style;
                    switch (rowStyle) {
                        case ColumnStatus.UnfilledMandatory.id: updateColumnClass(column, ColumnStatus.UnfilledMandatory.name); break;
                        case ColumnStatus.WrongDataFormat.id: updateColumnClass(column, ColumnStatus.WrongDataFormat.name); break;
                        case ColumnStatus.Unfilled.id: updateColumnClass(column, ColumnStatus.Unfilled.name); break;
                        case ColumnStatus.Filled.id: updateColumnClass(column, ColumnStatus.Filled.name); break;
                        default: break;
                }
            })
        })
    }, [wrongData])

    //Scroll buttons logic
    useEffect(() => {
        const container = tableRef.current;

        const handleScroll = () => {
            if (container) {
                setShowLeftScroll(container.scrollLeft > 0);
                setShowRightScroll(container.scrollLeft + container.clientWidth < container.scrollWidth);
            }
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function validateRows(rows) {
        let temporaryMandatoryCellsAreEmptyOrWrongDataType = 0;
        let temporaryCellsAreWrongDataTypeOrFormat = 0;
        let temporaryCellsAreEmptyButNotMandatory = 0;
        const tempWrongData = [];

        for (let row = 0; row < rows.length; row++) {
            const rowData = Object.values(rows[row].cells);
            for (let col = 0; col < rowData.length; col++) {
                const cell = rowData[col];
                const mappingData = dataMappings[mappingTemplate[col]];
                let [cellStyle, wrongColAndFunc] = validateCell(cell.text, mappingData);

                // validate date range period
                if (mappingData.fieldName === 'endDate' && cellStyle === CellValidationLevel.Default) {
                    const startDateCell = rowData[col - 1];
                    if (startDateCell.style === CellValidationLevel.Default &&
                        !validateDateRange(startDateCell?.text, cell?.text)
                    ) {
                        cellStyle = CellValidationLevel.Mandatory;
                        wrongColAndFunc = { [mappingData.name]: mappingData.validatorName };
                        startDateCell.style = cellStyle;
                        temporaryMandatoryCellsAreEmptyOrWrongDataType++;
                    }
                }

                const [wrongCol, wrongFunc] = wrongColAndFunc ? Object.entries(wrongColAndFunc)[0] : [];
                if (wrongColAndFunc) {
                    tempWrongData.push({ wrongCol, wrongFunc })
                }

                cell.text = cell.text?.trim();
                cell.style = cellStyle;
                cell.validatorName = wrongFunc || '';
                cell.wrongCol = wrongCol || '';

                switch (cell.style) {
                    case CellValidationLevel.Mandatory:
                        temporaryMandatoryCellsAreEmptyOrWrongDataType++;
                        break;
                    case CellValidationLevel.WrongFormat:
                        temporaryCellsAreWrongDataTypeOrFormat++;
                        break;
                    case CellValidationLevel.Empty:
                        temporaryCellsAreEmptyButNotMandatory++;
                        break;
                    default:
                        break;
                }
            }
        }

        const reducedWrongData = tempWrongData.reduce((uniqueArr, currentObj) => {
            const exists = uniqueArr.some(obj => obj.wrongCol === currentObj.wrongCol && obj.wrongFunc === currentObj.wrongFunc);
            if (!exists) {
                uniqueArr.push(currentObj);
            }
            return uniqueArr;
        }, []);

        setMandatoryCellsAreEmptyOrWrongDataType(temporaryMandatoryCellsAreEmptyOrWrongDataType);
        setCellsAreWrongDataTypeOrFormat(temporaryCellsAreWrongDataTypeOrFormat);
        setCellsAreEmptyButNotMandatory(temporaryCellsAreEmptyButNotMandatory);
        setValidationRows(rows);
        setWrongData(reducedWrongData);
    }

    const getBorderRadiusStyle = (index, arrayLength) => ({
        borderTopLeftRadius: index === 0 ? '14px' : 0,
        borderTopRightRadius: index === arrayLength - 1 ? '14px' : 0,
    });

    const openMandatoryCellsWithErrorsModal = () => {
        setIsErrorModalOpen(true);
        setErrorModalType(ErrorModalType.MandatoryCellsWithErrors);
    }

    const openOptionalCellsWithErrorsModal = () => {
        setIsErrorModalOpen(true);
        setErrorModalType(ErrorModalType.OptionalCellsWithErrors);
    }

    const closeErrorsModal = () => {
        setIsErrorModalOpen(false);
    }

    const handleScrollRight = () => {
        const container = tableRef.current;
        if (container) {
            container.scrollLeft += SCROLL_AMOUNT;
        }
    }

    const handleScrollLeft = () => {
        const container = tableRef.current;
        if (container) {
            container.scrollLeft -= SCROLL_AMOUNT;
        }
    }

    return (
        <React.Fragment>
            <CellErrorsModal open={isErrorModalOpen} onClose={closeErrorsModal} type={errorModalType} wrongData={wrongData} />
            <div className={styles.title}>3. Validation</div>
            <div className={styles.fileName}>{fileMetadata.path}</div>
            <div className={styles.mistakes}>
                <div className={styles.errorsInfo}>
                    <div>
                        <p className={styles.mandatory}>{cellsAreEmptyButNotMandatory} cells are empty but not mandatory</p>
                    </div>
                    <div className={styles.invalidContainer}>
                        <p className={styles.invalid}>{mandatoryCellsAreEmptyOrWrongDataType} mandatory cells are empty or wrong data type</p>
                        <div onClick={openMandatoryCellsWithErrorsModal} className={styles.viewButton}>View</div>
                        <RemoveRedEyeOutlinedIcon fontSize='small' />
                    </div>
                    <div className={styles.pkDuplicateContainer}>
                        <p className={styles.pkDuplicate}>{cellsAreWrongDataTypeOrFormat} cells are wrong data type or format</p>
                        <span onClick={openOptionalCellsWithErrorsModal} className={styles.viewButton}>View</span>
                        <RemoveRedEyeOutlinedIcon fontSize='small' />
                    </div>
                </div>
                <BrandCheckbox $value={displayFieldsWithErrorsOnly} label="Display fields with errors only" />
            </div>

            <div className={styles.mainContainer}>
                <div className={styles.mappingTable} ref={tableRef}>
                    <div className={styles.mappingTop}>
                        {mappingTemplate.map((column, index) => {
                            return (
                                <div
                                    style={getBorderRadiusStyle(index, mappingTemplate.length)}
                                    className={styles.mappingSelect}
                                    key={index}
                                >
                                    <div
                                        style={getBorderRadiusStyle(index, mappingTemplate.length)}
                                        className={styles.colHeader}
                                        id={column}
                                    >
                                        <span className={styles.label}>{dataMappings[column].required ? column + '*' : column}</span>
                                    </div>
                                </div>);
                        })}
                    </div>

                    {showLeftScroll && (
                        <div className={`${styles.scrollButton} ${styles.leftScrollIcon}`} onClick={handleScrollLeft}>
                            <ChevronLeftRoundedIcon style={blackIcon} />
                        </div>
                    )}

                    <BrandCanvasTable
                        id='redValidatedTableContainer'
                        options={tableOptions}
                        rows={filteredRows}
                        onChange={(table, cdata) => {
                            if (!state.findSongsInDb) {
                                if (!retryUpload) {
                                    setState(state => (state.findSongsInDb = true, { ...state }))
                                }
                            }
                            const len = cdata.rows.len;
                            delete cdata.rows.len;
                            validateRows(Object.values(cdata.rows));
                            cdata.rows.len = len;
                        }}
                        className={styles.table}
                        style={{ width: mappingTemplate.length * 250 }}
                        styles={tableStyles}
                    />

                    {showRightScroll && (
                        <div className={`${styles.scrollButton} ${styles.rightScrollIcon}`} onClick={handleScrollRight}>
                            <ChevronRightRoundedIcon style={blackIcon} />
                        </div>
                    )}

                </div>
            </div>
        </React.Fragment>
    );
}
