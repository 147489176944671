import React from 'react'
import { BrandModal } from '../../CoreComponents/BrandModal'
import { Box, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Circle from '@mui/icons-material/Circle';
import { checkCellError } from '../../utils/ValidationUtils';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '30vw',
    maxHeight: '36vh',
    overflowY: 'auto',
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.lightGray,
        borderRadius: '2px',
        border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.primary.darkGray,
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.primary.black,
        borderRadius: '2px',
        border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    },
  },
  wrongDataModalRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '14px',
    marginBottom: '14px',
  },
  header: { 
    display: 'flex', 
    justifyContent: 'space-between', 
    flexDirection: 'row', 
    alignItems: 'center' 
  }
}))

export const ErrorModalType = {
  MandatoryCellsWithErrors: 0,
  OptionalCellsWithErrors: 1,
};

const CellErrorsModal = ({ open, onClose, type, wrongData }) => {
  const styles = useStyles();
  const theme = useTheme();

  if (open && (type !== ErrorModalType.MandatoryCellsWithErrors && type !== ErrorModalType.OptionalCellsWithErrors)) {
    onClose();
    return null;
  }

  const circleIconStyles = {
    fontSize: 6, marginRight: 2
  }

  const cancelIconStyles = {
    color: theme.palette.primary.main, 
    cursor: 'pointer',
  }

  const getHeaderStyles = (type) => {
    switch (type) {
      case ErrorModalType.MandatoryCellsWithErrors:
        return { color: theme.palette.primary.delete, fontWeight: 'bold' };
      case ErrorModalType.OptionalCellsWithErrors:
        return { color: theme.palette.primary.warning, fontWeight: 'bold' };
      default:
        return {};
    }
  };

  const ModalHeader = ({ type, onClose }) => (
    <div className={styles.header}>
      <div style={getHeaderStyles(type)}>
        {type === ErrorModalType.MandatoryCellsWithErrors
          ? "Columns that are empty or have wrong data type"
          : "Optional Columns that have cells with wrong data type or format"}
      </div>
      <span onClick={onClose}>
        <CancelOutlinedIcon sx={cancelIconStyles} />
      </span>
    </div>
  );
  
  const ModalRow = ({ iconStyles, column, error }) => (
    <div className={styles.wrongDataModalRow}>
      <Circle sx={iconStyles} />
      {column} {error}
    </div>
  );
  
  const getModalContent = (type) => {
    const isMandatory = type === ErrorModalType.MandatoryCellsWithErrors;
    const filteredData = wrongData.filter(el =>
      isMandatory ? el.wrongFunc[0] === "required" : el.wrongFunc[0] !== "required"
    );
  
    return (
      <>
        <ModalHeader type={type} onClose={onClose} />
        {filteredData.length > 0 ? (
          filteredData.map((el, i) => (
            <ModalRow
              key={`${el.wrongCol}${i}`}
              iconStyles={circleIconStyles}
              column={el.wrongCol}
              error={
                isMandatory
                  ? `column is required`
                  : `column has a ${checkCellError(el.wrongFunc[0])}`
              }
            />
          ))
        ) : (
          <div className={styles.wrongDataModalRow}>No wrong data found</div>
        )}
      </>
    );
  };

  return (
    <BrandModal open={open} onClose={onClose}>
      <Box className={styles.container}>
        {getModalContent(type)}
      </Box>
    </BrandModal>
  );
};

export default CellErrorsModal;