import { Box, Checkbox, FormControl, FormControlLabel, Typography, makeStyles } from "@material-ui/core";
import { BrandModal } from "../CoreComponents/BrandModal";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import SongsAutocomplete from "./SongsAutocomplete";
import { useState } from "react";
import { Warning } from "@material-ui/icons";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { BrandLink } from "../CoreComponents/BrandLink";
import ActionChooser from "./ActionChooser";
import { BrandTooltipWithIcon } from "../CoreComponents/BrandTooltip";
import { ExactWordTooltipMessage } from "../../constants/messageConstants";
import { ButtonSize } from "../../constants/buttonConstants";
import { BrandLoaderDots } from "../CoreComponents/BrandLoader";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: '750px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
    },
    modalHeader: {
        fontWeight: 'bold'
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    infoContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        justifyContent: 'space-around'
    },
    parentalSongAndTooltipWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkboxesWarningMessageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '15px',
        marginTop: '25px'
    },
    unmergeWarningMessage: {
        color: 'yellow',
        fontSize: '13px'
    },
    checkboxesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    formControlLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '35px'
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        columnGap: '20px',
        marginTop: '20px',
        '& button': {
            width: '153px',
        }
    },
    disabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.lightGray
        }
    },
    bottomUnmergeWarningsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '15px',
        marginTop: '25px'
    },
    warningIcon: {
        color: 'yellow'
    },
    moveWarningMessage: {
        color: 'yellow',
        fontSize: '13px',
        marginRight: '20px'
    },
    singleButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    successMessageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
        width: '750px',
        flexDirection: 'column'
    },
    successMessage: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    confirmButton: {
        width: '130px',
        color: 'black',
        textTransform: 'none',
        '&:hover': {
            fontWeight: 'normal'
        },
        padding: '7.5px 15px'
    }
}));

const MoveSongModal = ({ 
    song, 
    open, 
    onClose,
    refreshData
}) => {
    const defaultSelectedOption = song && song.parentId
        ? { 
            id: song.parentId, 
            artist: song.parentArtist, 
            displayName: song.parentTitle 
        } 
        : null;

    const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
    const [successfulMergeMessage, setSuccessfulMergeMessage] = useState(false);
    const [isMovingSongs, setIsMovingSongs] = useState(false);

    const abortableFetch = useAbortableFetch();

    const styles = useStyles();

    const handleMoveSong = async () => {
        setIsMovingSongs(true);
        try {
            await abortableFetch('PUT', '/api/merge-history/move-song-hierarchy', {
                query: { id: song.id, parentId: selectedOption.id ?? null }
            });

            setSuccessfulMergeMessage(true);
            refreshData();
        } catch (error) {
            console.log(error);
        } finally {
            setIsMovingSongs(false);
        }

    }

    return (
        <BrandModal open={open} onClose={onClose}>
            { successfulMergeMessage ? (
                <Box className={styles.mainWrapper}>
                    <Box className={styles.successMessageWrapper}>
                        <Typography className={styles.successMessage}>
                            Successfully Moved "{song.displayName}" and all children songs under "{selectedOption.displayName}".
                        </Typography>
                    </Box>
                    <Box className={styles.singleButtonWrapper}>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            onClick={onClose}
                        >
                            OK
                        </TempBrandButton>
                    </Box>
                </Box>
            ) : (
                <Box className={styles.mainWrapper}>
                    <Typography variant='h6' className={styles.modalHeader}>
                        Move "{song.displayName}"
                    </Typography>
                    <Box className={styles.infoWrapper}>
                        <Box className={styles.infoContentWrapper}>
                            <Typography variant='body2'>Song title</Typography>
                            <Typography>{song.displayName}</Typography>
                        </Box>
                        <Box className={styles.infoContentWrapper}>
                            <Typography variant='body2'>Artist</Typography>
                            <Typography>{song.artist}</Typography>
                        </Box>
                            <Box className={styles.infoContentWrapper}>
                                <div className={styles.parentalSongAndTooltipWrapper}>
                                    <Typography variant='body2'>Parental Song</Typography>
                                    <BrandTooltipWithIcon title={ExactWordTooltipMessage} />
                                </div>
                                <SongsAutocomplete
                                    songId={song.id}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption}
                                />
                            </Box>
                    </Box>
                    <Box className={styles.checkboxesWarningMessageWrapper}>
                        <Warning fontSize="medium" className={styles.warningIcon} />
                        <Typography variant='body2' className={styles.unmergeWarningMessage}>
                            By changing the song's parent - the whole songs' history(if applicable) will be moved directly under the new parent song as well
                        </Typography>
                    </Box>
                    <Box className={styles.buttonsWrapper}>
                        <TempSecondaryBrandButton
                            size={ButtonSize.SMALL}
                            variant='outlined'
                            onClick={onClose}
                        >
                            Cancel
                        </TempSecondaryBrandButton>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            disabled={!selectedOption}
                            onClick={handleMoveSong}
                        >
                            {isMovingSongs ? <BrandLoaderDots/> : 'Move'}
                        </TempBrandButton>
                    </Box>
                </Box>
            ) }
        </BrandModal>
    )
}

export default MoveSongModal;