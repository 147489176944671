import React, { Fragment, useEffect, useState, useRef } from 'react';
import { postData, getData, deleteData, putData } from '../utils/FetchUtils'
import BrandTable from '../CoreComponents/BrandTable'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Avatar, Divider, IconButton, TablePagination, Typography, Popover, MenuList, Tooltip, Box, CircularProgress } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { BrandModal, BrandDeleteModal } from '../CoreComponents/BrandModal'
import { makeStyles } from '@material-ui/core';
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { BrandInput, BrandSearchInput } from '../CoreComponents/BrandInput';
import { BrandLoader } from '../CoreComponents/BrandLoader';
import RefreshIcon from '@material-ui/icons/Refresh';
import { textIsNumber, textIsEmpty, textIsIsrc, textIsUpc, textIsDate } from '../utils/ValidationUtils';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { GetSpotifySongAutocomplete } from './GetSpotifySongAutocomplete';
import { ArtistAutoComplete } from '../ManageArtists/ArtistAutoComplete';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { BrandAlert } from '../CoreComponents/BrandAlert'
import { NavLink } from 'react-router-dom';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import TuneIcon from '@material-ui/icons/Tune';
import { useDebounce } from '../../hooks/useDebounce';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import { tableRowsPerClientViewHeight } from '../Portfolio/SongPortfolio';
import { useStoreContext } from '../../store/Store';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import { AdditionalIsrcsUpcs } from './AdditionalIsrcsUpcs';
import { SONGS_DEFAULT_ROWS_PER_PAGE, SONGS_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../utils/PaginationUtils';
import { BrandTooltipWithIcon } from '../CoreComponents/BrandTooltip';
import { DisabledMergeToolTooltipMessage, ExactWordTooltipMessage } from '../../constants/messageConstants';
import { ButtonSize } from '../../constants/buttonConstants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MergeSongModal from './MergeSongModal';

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validateArtist: (value) => {
        const errors = []
        if (!textIsNumber(value)) {
            errors.push('Value must be a number.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validateIsrc: (value) => {
        const errors = []
        if (value && !textIsIsrc(value)) {
            errors.push('Value must be of format ISRC.')
        }
        return errors;
    },
    validateUpc: (value) => {
        const errors = []
        if (value && !textIsUpc(value)) {
            errors.push('Value must be of format UPC.')
        }
        return errors;
    },
    validateDate: (value) => {
        const errors = []
        if (value && !textIsDate(value)) {
            errors.push('Value must be in format YYYY-MM-DD.')
        }
        return errors;
    }
}

const useStyles = makeStyles(theme => ({
    text: {
        marginBottom: theme.spacing(8.5)
    },
    songsContainer: {
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '0px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        },
        position: 'relative'
    },
    container: {
        "& label": {
            marginBottom: theme.spacing(3.5),
        }
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        "& button": {
            width: '93px'
        },
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.primary.contrastText}`
        }
    },
    inputGroup: {
        display: 'flex',
        "& label:first-child": {
            marginRight: theme.spacing(2),
        },
        '& label': {
            width: '100%'
        },
        "& .MuiAutocomplete-root": {
            width: '100%',
            '& .MuiIconButton-root': {
                marginRight: '-20px'
            }
        }
    },
    search: {
        display: 'flex',
        "& .MuiInputBase-root": {
            width: '445px'
        },
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    refreshAndUploadCsvButtons: {
        display: 'flex',
        gap: theme.spacing(2),

    },
    rotateIcon: {
        animation: "$spin 1s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    root: {
        width: '100%',
        height: '60vh',
        overflow: 'auto'
    },
    alert: {
        marginTop: theme.spacing(3.5),
        display: 'flex',
        justifyContent: 'center',
    },
    songContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: '20vw',
        maxWidth: '38vw'
    },
    songActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.primary.contrastText}`
        }
    },
    coverArt: {
        width: '26px',
        height: '26px'
    },
    songsMainTitle: {
        font: 'normal normal bold 25px Roboto'
    },
    popOver: {
        "& .MuiPaper-root": {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    divider: {
        borderTop: `1px solid ${theme.palette.primary.contrastText}`,
        opacity: 0.32,
    },
    apply: {
        textAlign: 'right',
        marginRight: '15px',
        marginTop: '15px'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    fiberRecordIcon: {
        color: theme.palette.primary.warning,
        fontSize: 10
    },
    warningMessage: {
        color: theme.palette.primary.attention,
        font: 'normal normal normal 16px/36px Roboto'
    },
    alreadyVerifiedSongMessage: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        left: '-20px'
    },
    settingsIcon: {
        border: `1px solid ${theme.palette.text.lightGray}`,
        color: theme.palette.text.lightGray,
        height: 40,
        width: 40,
    },
    iconButton: {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
        '&:disabled': {
            color: theme.palette.primary.darkGray,
            cursor: 'not-allowed',
        },
    },
    deleteAndEditContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fiberManualRecordIcon: {
        color: theme.palette.primary.warning,
        fontSize: 10
    },
    mainDeleteErrorTypography: {
        paddingBottom: theme.spacing(5.5),
        color: theme.palette.primary.alert,
        font: 'normal normal normal 16px/26px Roboto',
    },
    mergedSongsTableHeading: {
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.alert
    },
    deleteModalHeading: {
        paddingBottom: theme.spacing(4)
    },
    viewOptionsHeading: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    titleWrapper: {
        display: 'flex'
    },
    mergeIcon: {
        color: theme.palette.primary.main,
        display: 'flex',
        marginLeft: '4px'
    },
    songMergeInfoMainContainer: {
        cursor: 'default'
    },
    songMergeInfo: {
        maxHeight: '250px',
        position: 'absolute',
        zIndex: '1',
        backgroundColor: theme.palette.background.default,
        padding: '10px',
    },
    songMergeInfoLabel: {
        paddingRight: '10px'
    },
    songMergeInfoValue: {
        paddingBottom: '4px',
        paddingRight: '10px',
        color: theme.palette.primary.main,
    },
    searchTooltipContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    disabledMergeButtonTooltipText: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.alert,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',

        textAlign: 'left',
        font: 'normal normal normal 16px/26px Roboto',
        padding: '12px',
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(15),
    },
    warningIcon: {
        color: theme.palette.primary.alert,
        marginRight: theme.spacing(1),
    }
}));

const EditModal = ({ rowData, onClose, onSubmit }) => {
    const styles = useStyles();

    const displayName = useState(rowData.displayName);
    const artist = useState({ name: rowData.rightHolderName, id: rowData.artistId })
    const genre = useState(rowData.genre);
    const coverArt = useState(rowData.coverArt);
    const producers = useState(rowData.producers);
    const writers = useState(rowData.writers);
    const isrc = useState(rowData.isrc);
    const upc = useState(rowData.upc);
    const album = useState(rowData.album);
    const releaseDate = useState(rowData.releaseDate?.split('T')[0] || '');

    const displayNamePassed = useState(true);
    const artistPassed = useState(true);
    const genrePassed = useState(true);
    const coverArtPassed = useState(true);
    const producersPassed = useState(true);
    const ISRCPassed = useState(true);
    const UPCPassed = useState(true);
    const writersPassed = useState(true);
    const albumPassed = useState(true);
    const releaseDatePassed = useState(true);

    const [alert, setAlert] = useState('');

    async function submitEditing(e) {
        e.preventDefault();
        await putData(process.env.REACT_APP_SERVER_HOST + '/api/song/', {
            genre: genre[0],
            coverArt: coverArt[0],
            producers: producers[0],
            writers: writers[0],
            isrc: isrc[0],
            upc: upc[0],
            album: album[0],
            releaseDate: releaseDate[0],
            displayName: displayName[0],
            artistId: artist[0].id,
            id: rowData.id
        }).then(data => {
            onClose();
            onSubmit();
        }, error => {
            setAlert(error.message);
        });
    }

    const checkForDataChange = () => {
        return (
            displayName[0] !== rowData.displayName ||
            artist[0]?.name !== rowData.rightHolderName ||
            genre[0] !== rowData.genre ||
            coverArt[0] !== rowData.coverArt ||
            producers[0] !== rowData.producers ||
            writers[0] !== rowData.writers ||
            isrc[0] !== rowData.isrc ||
            upc[0] !== rowData.upc ||
            album[0] !== rowData.album ||
            (releaseDate[0] !== rowData.releaseDate?.split('T')[0] || '')
        );
    }

    const checkForDataErrors = () => {
        return (
            !displayNamePassed[0] ||
            !artistPassed[0] ||
            !genrePassed[0] ||
            !coverArtPassed[0] ||
            !producersPassed[0] ||
            !writersPassed[0] ||
            !albumPassed[0] ||
            !releaseDatePassed[0] ||
            !ISRCPassed[0] ||
            !UPCPassed[0]
        );
    }

    const shouldBeDisabled = () => {
        const isDataInvalid = checkForDataErrors();
        const hasDataChanged = checkForDataChange();

        return isDataInvalid || !hasDataChanged;
    }

    return (
        <div className={styles.container}>
            <Typography
                variant="h5"
                style={{ paddingBottom: 20 }}
            >
                Edit Song
            </Typography>
            <form onSubmit={submitEditing}>
                <div className={styles.inputGroup}>
                    <BrandInput $value={displayName} label='Song Display Title' validator={dataValidators.isRequired} validatorPassed={displayNamePassed} required={true} />
                    <ArtistAutoComplete label='Artist' onSelectedArtist={artist[1]} selectedArtist={artist[0]} iconWithTooltip={true} tooltipMessage={ExactWordTooltipMessage}/>
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={genre} label='Genre' />
                    <BrandInput $value={coverArt} label='Cover Art' disabled={true} />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={producers} label='Producers' />
                    <BrandInput $value={writers} label='Writers' />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={isrc} label='ISRC' validator={dataValidators.validateIsrc} validatorPassed={ISRCPassed} />
                    <BrandInput $value={upc} label='UPC' validator={dataValidators.validateUpc} validatorPassed={UPCPassed} />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={album} label='Album' />
                    <BrandInput $value={releaseDate} validator={dataValidators.validateDate} validatorPassed={releaseDatePassed} label='Release Date' />
                </div>
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                <div className={styles.actions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={onClose}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={shouldBeDisabled()}
                        type='submit'
                    >
                        Save
                    </TempBrandButton>
                </div>
            </form>
        </div >
    );
}

export const ConnectSong = ({ song, onClose }) => {
    const styles = useStyles();
    const [selectedSong, setSelectedSong] = useState(null);
    const [alert, setAlert] = useState('');
    const [disabledConnect, setDisabledConnect] = useState(true);
    const [loadingConnectSong, setLoadingConnectSong] = useState(false);
    const [existingVerifiedSong, setExistingVerifiedSong] = useState([]);
    const [mergeSongConfirmation, setMergeSongConfirmation] = useState(false);

    const CHECKBOX_LABEL = `Do you want to merge ${song.displayName} into ${existingVerifiedSong[0]?.artistName} - ${existingVerifiedSong[0]?.songTitle}?`

    function onSubmit(e) {
        e.preventDefault();
        if (selectedSong && selectedSong.id) {
            setLoadingConnectSong(true);
            const body = {
                id: song.id || song.songId,
                spotifySongData: selectedSong
            }
            postData(process.env.REACT_APP_SERVER_HOST + `/api/song/spotify-verify`, body)
                .then(data => {
                    setExistingVerifiedSong(data.existingVerifiedSong ? data.existingVerifiedSong : []);
                    if (!data.existingVerifiedSong) onClose();
                })
                .catch((error) => {
                    console.log('------------------------------------------------------------');
                    console.log(error);
                    setAlert(error.message);
                })
                .finally(() => setLoadingConnectSong(false));
        }
    }

    function onSubmitMerge(e) {
        e.preventDefault();
        if (existingVerifiedSong.length > 0 && mergeSongConfirmation) {
            setLoadingConnectSong(true);
            const body = {
                songId: existingVerifiedSong[0]?.songId,
                songIdsToBeMerged: [song.id] || [song.songId]
            }
            postData(process.env.REACT_APP_SERVER_HOST + `/api/song/merge-songs`, body)
                .then(data => {
                    onClose();
                })
                .catch((error) => {
                    console.log('------------------------------------------------------------');
                    console.log(error);
                    setAlert(error.message);
                })
                .finally(() => setLoadingConnectSong(false));
        }
    }

    useEffect(() => {
        if (!selectedSong || !Object.keys(selectedSong).length) {
            setDisabledConnect(false);
            setExistingVerifiedSong([]);
        }
    }, [selectedSong]);

    return (
        <div className={styles.songContainer}>
            <form
                className={styles.songContainer}
                onSubmit={existingVerifiedSong.length > 0 ? onSubmitMerge : onSubmit}
            >
                <Typography variant='h5' component='div'>Connect Song {song.displayName}</Typography>
                {existingVerifiedSong.length > 0 &&
                    <Typography className={styles.warningMessage}>
                        This song is used to verify another song in the system - {existingVerifiedSong[0].artistName} - {existingVerifiedSong[0].songTitle}
                    </Typography>}
                <GetSpotifySongAutocomplete
                    label='Find on Spotify'
                    onSelectedSong={setSelectedSong}
                    setAlert={setAlert}
                />
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                {existingVerifiedSong.length > 0 &&
                    <BrandCheckbox
                        label={CHECKBOX_LABEL}
                        onChange={() => setMergeSongConfirmation(!mergeSongConfirmation)}
                    />
                }
                <div className={styles.songActions}>
                    <TempSecondaryBrandButton
                        variant='outlined'
                        onClick={onClose}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        disabled={existingVerifiedSong.length > 0 ? !mergeSongConfirmation : disabledConnect}
                        type='submit'
                    >
                        {loadingConnectSong ?
                            <BrandLoader
                                color='white'
                                width={73}
                                height={15}
                            />
                            :
                            'Connect'
                        }
                    </TempBrandButton>
                </div>
            </form>
        </div >
    );
}

const useUnverifySongStyles = makeStyles((theme) => ({
    contentWrapper: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        marginTop: '30px',
        justifyContent: 'flex-end'
    },
    unverifyButton: {
        backgroundColor: '#990F02',
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#530000'
        }
    }
}));

export const UnverifySong = ({ onClose, rowData, onFetch }) => {
    const styles = useUnverifySongStyles();

    const handleUnverifySong = async () => {
        const { id } = rowData;

        try {
            await postData(process.env.REACT_APP_SERVER_HOST + '/api/song/unverify', { songId: id });

            onClose();
            onFetch();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box className={styles.contentWrapper}>
            <Typography variant='h5'>
                Unverify song
            </Typography>
            <Typography variant='body2'>
                Are you sure you want to unverify the current song?
            </Typography>
            <Box className={styles.buttonsWrapper}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    onClick={onClose}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandDeleteButton
                    size={ButtonSize.SMALL}
                    onClick={handleUnverifySong}
                >
                    Unverify
                </TempBrandDeleteButton>
            </Box>
        </Box>
    )
};

const Songs = () => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const accountHolders = useState(false);
    const genre = useState(false);
    const producers = useState(false);
    const upc = useState(false);
    const createdOn = useState(false);
    const [groupings, setGroupings] = useState([]);
    const SORT_SONGS_BY_COLUMN = 'id';
    const SORT_SONGS_BY_TYPE = 'ASC';
    const [sortBy, setSortBy] = useState(SORT_SONGS_BY_COLUMN);
    const [sortType, setSortType] = useState(SORT_SONGS_BY_TYPE);

    const [allHeadCells] = useState([
        {
            id: 'displayName',
            label: 'Song Title',
            CellRender: ({ cellData, rowData }) => {
                const [isShown, setIsShown] = useState(false);
                const ref = useRef(null);
                const mergedSongs = rowData.mergedSongs && rowData.mergedSongs.length > 0 ? rowData.mergedSongs[0] : null;
                return (
                    <  >
                        {cellData ?
                            <div className={styles.titleWrapper}>
                                {cellData}
                                {mergedSongs &&
                                    <div
                                        className={styles.songMergeInfoMainContainer}
                                        onMouseEnter={() => setIsShown(true)}
                                        onMouseLeave={() => setIsShown(false)}
                                        ref={ref}
                                    >
                                        <div className={styles.mergeIcon}>
                                            {'+'}<MergeTypeIcon fontSize='small' />
                                        </div>
                                        {isShown &&
                                            <div className={styles.songMergeInfo}>
                                                <div className={styles.songMergeInfoLabel}>Artist:</div>
                                                <div className={styles.songMergeInfoValue}>{rowData.artistName || 'Unknown'}</div>
                                                <div className={styles.songMergeInfoLabel}>Song merged into:</div>
                                                <div className={styles.songMergeInfoValue}>{mergedSongs}</div>
                                                <div className={styles.songMergeInfoLabel}>View whole hierarchy:</div>
                                                <div><NavLink className={styles.songMergeInfoValue} to='/merge-history'>Merge history</NavLink></div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'rightHolderName',
            label: 'Artist',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData}`
                            :
                            'Unknown'
                        }
                    </>
                );
            }
        }, {
            id: 'accountHolders',
            label: 'Account Holder(s)',
            CellRender: ({ cellData }) => {
                const parsedAccountHolders = cellData;
                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                if (!parsedAccountHolders || parsedAccountHolders.length === 0) {
                    return "N/A";
                } else if (parsedAccountHolders.length === 1) {
                    return parsedAccountHolders[0];
                }

                return (
                    <div>
                        <div
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            {`${parsedAccountHolders[0]}; +${parsedAccountHolders.length - 1}`}
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                parsedAccountHolders.map(x => {
                                    return (
                                        <Typography key={`${x}${Math.random()}`} color='primary'>{x}</Typography>
                                    )
                                })
                            }
                        </Popover>
                    </div>
                );
            }
        },
        {
            id: 'genre',
            label: 'Genre',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData.substr(0, 20)}...`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'coverArt',
            label: 'Cover Art',
            notSortable: true,
            CellRender: ({ cellData, rowData }) => {
                return (
                    <  >
                        {cellData ?
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayCoverArtModal(true);
                                    setCoverArtLink(cellData);
                                }}
                            >
                                <Avatar
                                    alt={`Avatar of ${rowData.displayName}`}
                                    src={cellData}
                                    className={styles.coverArt}
                                    variant='square'
                                />
                            </IconButton>
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'producers',
            label: 'Producers',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'writers',
            label: 'Writers',
            CellRender: ({ cellData }) => {
                const parsedWriters = cellData?.split(';')
                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                return (
                    <>
                        {cellData ?
                            <div>
                                <div
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    {`${cellData?.substring(0, 20)}...`}
                                </div>
                                <Popover
                                    id="mouse-over-popover"
                                    className={styles.popover}
                                    classes={{
                                        paper: styles.paper,
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    {
                                        parsedWriters.map(x => {
                                            return (
                                                <Typography key={`${x}${Math.random()}`} color='primary'>{x}</Typography>
                                            )
                                        })
                                    }
                                </Popover>
                            </div>
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'isrcs',
            label: 'ISRC',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData.length > 0 ?
                            <AdditionalIsrcsUpcs data={cellData} />
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'upc',
            label: 'UPC',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'album',
            label: 'Album',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'createdOn',
            label: 'Created On',
            notSortable: true,
            CellRender: ({ cellData }) => {
                const createdOn = new Date(cellData)
                return (
                    <>
                        {createdOn.getTime() ?
                            `${createdOn.getFullYear()}-${createdOn.getMonth() < 9 ? '0' : ''}${createdOn.getMonth() + 1}-${createdOn.getDate() < 10 ? '0' : ''}${createdOn.getDate()}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            CellRender: ({ cellData }) => {
                const releaseDate = new Date(cellData)
                return (
                    < >
                        {releaseDate.getTime() ?
                            `${releaseDate.getFullYear()}-${releaseDate.getMonth() < 9 ? '0' : ''}${releaseDate.getMonth() + 1}-${releaseDate.getDate() < 10 ? '0' : ''}${releaseDate.getDate()}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            },
        },
        {
            id: 'spotifySongId',
            label: 'Verification',
            CellRender: ({ rowData }) => {
                const { spotifySongId, id } = rowData;
                const [verAlert, setVerAlert] = useState('');

                function onVerifiedCellClick() {
                    setVerAlert('song already verified')

                    setTimeout(() => {
                        setVerAlert('');
                    }, "2000")
                }

                return (
                    <div style={{ position: 'relative' }}>
                        <SecondaryBrandButton
                            variant='text'
                            key={id}
                            startIcon={spotifySongId ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (spotifySongId) {
                                    onVerifiedCellClick();
                                    //Disable reverify song until problem with updating data is solved

                                    // handleShowUnverifySongModal();
                                    // setSelectedSongToUnverify(rowData);
                                } else {
                                    setDisplayConnectSong(true);
                                    setSelectedSongToConnect(rowData);
                                }
                            }}
                        >
                            {spotifySongId ? 'Verified' : 'Verify'}
                        </SecondaryBrandButton>
                        <div className={styles.alreadyVerifiedSongMessage}>
                            {verAlert !== '' && <BrandAlert>Song already verified!</BrandAlert>}
                        </div>
                    </div>
                );
            }
        },
        {
            id: 'deleteAndEdit',
            label: '',
            hideHeadLabel: true,
            notSortable: true,
            CellRender: ({ rowData }) => {
                const mergedSongs = rowData.mergedSongs && rowData.mergedSongs.length > 0;
                const disableMergeIcon = mergedSongs; // || !rowData.spotifySongId;
                return (
                    <div className={styles.deleteAndEditContainer}>
                        <Tooltip title='Delete'>
                            <IconButton
                                className={styles.iconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteRowClick(rowData);
                                }}>
                                <DeleteOutlineOutlinedIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Edit'>
                            <IconButton
                                className={styles.iconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEditRowClick(rowData);
                                }}
                            >
                                <EditOutlinedIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={disableMergeIcon ? DisabledMergeToolTooltipMessage : 'Merge'}
                            classes={{ tooltip: disableMergeIcon ? styles.disabledMergeButtonTooltipText : '' }}>
                            <div>
                                <IconButton
                                    className={styles.iconButton}
                                    disabled={disableMergeIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onMergeRowClick(rowData);
                                    }}
                                >
                                    <MergeTypeIcon fontSize='small' />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                );
            }
        }
    ]);

    const [deleteModalHeadCells] = useState([
        {
            id: 'isrc',
            label: 'ISRC',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'displayName',
            label: 'Song Title',
            CellRender: ({ cellData }) => {
                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);
                return (
                    <>
                        {cellData ?
                            <div>
                                <div
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    {`${cellData.length > 11 ? cellData.slice(0, 11) + '...' : cellData}`}
                                </div>
                                {cellData.length > 11 &&
                                    <Popover
                                        id="mouse-over-popover"
                                        className={styles.popover}
                                        classes={{
                                            paper: styles.paper,
                                        }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <Typography color='primary'>{cellData}</Typography>
                                    </Popover>
                                }
                            </div>
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            CellRender: ({ cellData }) => {
                return (
                    <  >
                        {cellData ?
                            `${cellData.length > 11 ? cellData.slice(0, 10) : cellData}`
                            :
                            <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                        }
                    </>
                );
            }
        },
    ]);

    const [headCells, setHeadCells] = useState(allHeadCells.filter(x => x.id !== 'accountHolders' && x.id !== 'genre' && x.id !== 'producers' && x.id !== 'upc' && x.id !== 'createdOn'));
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [selectedSongRow, setSelectedSongRow] = useState(null);
    const [selectedRowToEditData, setSelectedRowToEditData] = useState({});
    const [showEditSongModal, setShowEditSongModal] = useState(false);
    const [showUnverifySongModal, setShowUnverifySongModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState({});
    const [displayDeleteSongModal, setDisplayDeleteSongModal] = useState(false);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(SONGS_ROWS_PER_PAGE_OPTIONS);
    const [rowsPerPage, setRowsPerPage] = useState(SONGS_DEFAULT_ROWS_PER_PAGE);
    const [offset, setOffset] = useState(0);
    const [selectedSongToUnverify, setSelectedSongToUnverify] = useState({});

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [error, setError] = useState('');
    const [checked, setChecked] = useState(false);

    const [displayConnectSong, setDisplayConnectSong] = useState(false);
    const [selectedSongToConnect, setSelectedSongToConnect] = useState({});
    const [displayCoverArtModal, setDisplayCoverArtModal] = useState(false);
    const [coverArtLink, setCoverArtLink] = useState('');
    const [selectedRowToMerge, setSelectedRowToMerge] = useState({});
    const [displayMergeModal, setDisplayMergeModal] = useState(false);
    const [spotifyRefreshData, setSpotifyRefreshData] = useState({});

    const [songsMergedInCurrentSong, setSongsMergedInCurrentSong] = useState([]);

    const disableRefreshButtons = Object.keys(spotifyRefreshData).length === 0 ? false : true;

    const [disableDeleteButton, setDisableDeleteButton] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [deleteTableSortBy, setDeleteTableSortBy] = useState(SORT_SONGS_BY_COLUMN);
    const [deleteTableSortType, setDeleteTableSortType] = useState(SORT_SONGS_BY_TYPE);

    useEffect(() => {
        if (songsMergedInCurrentSong.length > 1) {
            onDeleteSong();
        }
    }, [deleteTableSortType, deleteTableSortBy]);

    const onFilterOpenClick = (event) => {
        accountHolders[1](groupings.includes('accountHolders'))
        genre[1](groupings.includes('genre'))
        producers[1](groupings.includes('producers'))
        upc[1](groupings.includes('upc'))
        createdOn[1](groupings.includes('createdOn'))
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setError('');
        setChecked(false);
    };

    function onEditRowClick(rowData) {
        setShowEditSongModal(true);
        setSelectedRowToEditData(rowData);
    }

    function onDeleteRowClick(rowData) {
        setSelectedRowToDelete(rowData);
        setDisplayDeleteSongModal(true);
    }

    function onMergeRowClick(rowData) {
        setSelectedRowToMerge(rowData);
        setDisplayMergeModal(true);
    }


    function onRowClick(event, row) {
        setSelectedSongRow(row);
    }

    const handleShowUnverifySongModal = () => setShowUnverifySongModal(true);
    const handleHideUnverifySongModal = () => setShowUnverifySongModal(false);

    const onDeleteSong = async () => {
        setDisableDeleteButton(true);
        setIsLoadingDelete(true);

        try {
            await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/song/', { ...selectedRowToDelete, confirmation: checked, deleteTableSortBy, deleteTableSortType })
                .then((data) => {
                    setSongsMergedInCurrentSong(data.songsMergedInCurrentSong ? data.songsMergedInCurrentSong : []);

                    if (!data.songsMergedInCurrentSong) {
                        setDisplayDeleteSongModal(false);
                        fetchSongs();
                        setError('');
                        setChecked(false);
                        setDisableDeleteButton(false);
                    }
                }
                );
        } catch (error) {
            setError(error.message);
            setDisableDeleteButton(false);
        } finally {
            setIsLoadingDelete(false);
        }
    };
    const { trigger: fetchSongs, loading: loadingFetch } = useAsyncAction(async () => {
        const encodedSearch = encodeURIComponent(searchQuery || '');
        const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/song/?offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}&filter=${encodedSearch}`);
        setRowCounts(fetchedData.total);
        setRows(fetchedData.items);
    });

    useEffect(() => {
        tableRowsPerClientViewHeight(setRowsPerPage, setRowsPerPageOptions, rowsPerPageOptions, 200)
    }, [])

    const { trigger: clearSongs, loading: isDeleting } = useAsyncAction(async () => {
        await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/song/all');

        setRowCounts(0);
        setRows([]);
    });

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page]);

    useEffect(() => {
        if (rowsPerPage !== 0) {
            fetchSongs();
            setSelectedSongRow(null);
        }
    }, [offset, searchQuery, sortBy, sortType]);

    const refreshSpotifyData = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/refresh`)
            .then(data => {
                setSpotifyRefreshData(data);
            }, error => {
                // setAlert(error.message)
            })
    }

    useEffect(() => {
        const intervalHandler = setInterval(() => {
            refreshSpotifyData();
        }, 30000);
        refreshSpotifyData();
        return () => {
            clearInterval(intervalHandler);
            setState(state => (state.selectedSongPortfolioHeadFilter = '', { ...state }));
        }
    }, [])

    useEffect(() => {
        setHeadCells(allHeadCells.filter(x => (x.id !== 'accountHolders' && x.id !== 'genre' && x.id !== 'producers' && x.id !== 'upc' && x.id !== 'createdOn')
            || (x.id === 'accountHolders' && accountHolders[0])
            || (x.id === 'genre' && genre[0])
            || (x.id === 'producers' && producers[0])
            || (x.id === 'upc' && upc[0])
            || (x.id === 'createdOn' && createdOn[0])
        ))
        fetchSongs();
    }, [offset, rowsPerPage, searchQuery, groupings])

    useEffect(() => {
        setPage(0);
    }, [searchQuery])

    useEffect(() => {
        setSearch(state.selectedSongPortfolioHeadFilter);
    }, [state.selectedSongPortfolioHeadFilter]);

    return (
        <div className={styles.songsContainer}>
            <BrandModal
                open={showUnverifySongModal}
                onClose={handleHideUnverifySongModal}
            >
                <UnverifySong
                    rowData={selectedSongToUnverify}
                    onClose={handleHideUnverifySongModal}
                    onFetch={fetchSongs}
                />
            </BrandModal>

            <BrandModal
                open={displayMergeModal}
                onClose={() => setDisplayMergeModal(false)}
            >
                <MergeSongModal
                    song={selectedRowToMerge}
                    onClose={() => {
                        setDisplayMergeModal(false)
                        fetchSongs()
                    }}
                />
            </BrandModal>

            <BrandModal
                open={displayConnectSong}
                onClose={() => setDisplayConnectSong(false)}
            >
                <ConnectSong
                    song={selectedSongToConnect}
                    onClose={() => {
                        setDisplayConnectSong(false)
                        fetchSongs()
                    }}
                />
            </BrandModal>
            <BrandModal
                open={displayCoverArtModal}
                onClose={() => setDisplayCoverArtModal(false)}
            // hasPadding={false}
            >
                <img src={coverArtLink} alt="Cover Art"></img>
            </BrandModal>
            <BrandModal
                open={showEditSongModal}
                onClose={() => setShowEditSongModal(false)}
            >
                <EditModal
                    rowData={selectedRowToEditData}
                    onClose={() => setShowEditSongModal(false)}
                    onSubmit={() => fetchSongs()}
                />
            </BrandModal>
            <BrandDeleteModal
                open={displayDeleteSongModal}
                onClose={() => {
                    setDisplayDeleteSongModal(false);
                    setSongsMergedInCurrentSong([]);
                    setDisableDeleteButton(false);
                    setError('');
                }}
                onDelete={() => onDeleteSong()}
                hasCustomScrollbar={
                    songsMergedInCurrentSong && songsMergedInCurrentSong.length > 3
                        ? true
                        : false
                }
                loading={
                    songsMergedInCurrentSong.length > 0 ? false : isLoadingDelete
                }
                disabled={disableDeleteButton || (error && !checked)}
            >
                <Typography variant="h5" className={styles.deleteModalHeading}>
                    Delete Song
                </Typography>
                {error ? (
                    <Box className={styles.errorContainer}>
                        <Box className={styles.warningIcon}>
                            <WarningAmberIcon fontSize="large" />
                        </Box>
                        <Typography
                            variant="h5"
                            className={styles.mainDeleteErrorTypography}
                        >
                            You cannot delete the song: <br />
                            {selectedRowToDelete.title} <br />
                            because there are reports attached to it.
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <div>
                            Are you sure you want to delete "{selectedRowToDelete.title}"?
                        </div>
                        <div className={styles.text}>
                            Once deleted you will not be able to restore the data.
                        </div>
                    </>
                )}
                {songsMergedInCurrentSong.length > 0 && (
                    <div>
                        <Typography className={styles.mergedSongsTableHeading}>
                            There are songs merged in this song, unmerge first!
                        </Typography>
                        <BrandTable
                            rows={songsMergedInCurrentSong}
                            headCells={deleteModalHeadCells}
                            checkboxless={true}
                            hidePagination={true}
                            setSortBy={setDeleteTableSortBy}
                            setSortType={setDeleteTableSortType}
                        />
                    </div>
                )}
            </BrandDeleteModal>
            <Typography variant="h6" className={styles.songsMainTitle}>
                Songs
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.searchTooltipContainer}>
                    <div className={styles.search}>
                        <BrandSearchInput
                            placeholder='Search for song'
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                            classes={{ input: styles.input }}
                        />
                    </div>
                    <BrandTooltipWithIcon title={ExactWordTooltipMessage} placement="bottom" />
                </div>
                <div className={styles.refreshAndUploadCsvButtons} >
                    <TempBrandButton
                        disabled={disableRefreshButtons}
                        capitalize={true}
                        onClick={async () => {
                            await postData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/refresh`)
                            refreshSpotifyData();
                            fetchSongs()
                        }}
                        startIcon={<RefreshIcon className={Object.keys(spotifyRefreshData).length ? styles.rotateIcon : null} />}
                    >
                        Refresh Spotify Songs
                    </TempBrandButton>
                    {process.env.REACT_APP_ENABLE_DELETE_SONG_BUTTON === 'true' ? <TempBrandDeleteButton
                        capitalize={true}
                        onClick={clearSongs}
                    >
                        Clear Songs
                    </TempBrandDeleteButton> : ""}
                    <div>
                        <IconButton className={styles.settingsIcon} onClick={onFilterOpenClick} disableTouchRipple>
                            <TuneIcon />
                        </IconButton>
                        <Popover
                            className={styles.popOver}
                            anchorEl={anchorEl}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuList>
                                <Typography className={styles.viewOptionsHeading} variant="subtitle2" component='div'>
                                    View Options
                                </Typography>
                                <BrandCheckbox $value={accountHolders} label='Account Holder(s)' />
                                <br />
                                <BrandCheckbox $value={genre} label='Genre' />
                                <br />
                                <BrandCheckbox $value={producers} label='Producers' />
                                <br />
                                <BrandCheckbox $value={upc} label='UPC' />
                                <br />
                                <BrandCheckbox $value={createdOn} label='Created On' />
                                <br />
                                <Divider className={styles.divider} ></Divider>
                                <div className={styles.apply} >
                                    <SecondaryBrandButton
                                        variant='text'
                                        onClick={() => {
                                            setGroupings([
                                                accountHolders[0] && 'accountHolders',
                                                genre[0] && 'genre',
                                                producers[0] && 'producers',
                                                upc[0] && 'upc',
                                                createdOn[0] && 'createdOn',
                                            ].filter(x => x));
                                        }}
                                    >
                                        APPLY
                                    </SecondaryBrandButton>
                                </div>
                            </MenuList>
                        </Popover>
                    </div>
                </div>
            </div>
            {loadingFetch || isDeleting ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Fragment>
                    <BrandTable
                        rows={rows}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        rowIsClickable={true}
                        onRowClick={onRowClick}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            setPage(newPage);
                            setSelectedSongRow(null);
                        }}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </Fragment>
            )}
        </div>
    );
}

export default Songs;