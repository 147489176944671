import { Button, Divider, IconButton, Link, MenuItem, MenuList, Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect';
import { BrandDateRange } from '../CoreComponents/BrandDatePicker'
import { getData, postData } from '../utils/FetchUtils';
import { formatNumber } from '../utils/NumberUtils';
import NftPortfolio from './NftPortfolio';
import SongPortfolio from './SongPortfolio';
import TuneIcon from '@material-ui/icons/Tune';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import { SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import * as Papa from 'papaparse';
import { BrandLoaderDots } from '../CoreComponents/BrandLoader';
import { useStoreContext } from '../../store/Store';
import { useDebounce } from '../../hooks/useDebounce';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import useAbortableFetch from '../../hooks/useAbortableFetch';
import CustomCheckbox from '../RevenueSplits/CustomCheckbox';
import CustomFilterDropdown from './CustomFilterDropdown';
import { NavLink } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { NavigationTypes } from '../../constants/navigationConstants';
import { userAccessByType } from '../utils/AccessUtils';
import { BrandTooltipWithIcon } from '../CoreComponents/BrandTooltip';
import { ExactWordTooltipMessage } from '../../constants/messageConstants';
import { ButtonSize } from '../../constants/buttonConstants';
import { QuarterPicker } from '../CoreComponents/QuarterPicker';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '1275px',
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: theme.palette.text.primary,
                }
            }
        },
    },
    title: {
        font: 'normal normal bold 25px Roboto',
        letterSpacing: '2.4px',
        color: theme.palette.text.lightYellow,
        marginBottom: theme.spacing(2),
    },
    selectsAndToolbarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            background: 'none',
            border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
            minWidth: '215px',

        }
    },
    selectGroup: {
        width: '100%',
    },
    defaultSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: theme.palette.text.lightGray,
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    accountHolderLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
        marginRight: theme.spacing(4),
    },
    defaultSelectLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
        marginRight: theme.spacing(2),
    },
    defaultSelectLabelOneAccHolder: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
        marginRight: theme.spacing(2),
    },
    defaultSelectLabelTo: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
    },
    dateSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: theme.palette.text.lightGray,
        gap: 10,
        '& .MuiButtonBase-root': {
            color: 'white'
        }
    },
    dateSelectOneAccHolder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: theme.palette.text.lightGray,
        gap: 10,
        '& .MuiButtonBase-root': {
            color: 'white'
        }
    },
    searchInput: {
        width: 370,
    },
    iconButton: {
        border: `1px solid ${theme.palette.text.lightGray}`,
        color: theme.palette.text.lightGray,
        height: 40,
        width: 40,
        marginLeft: theme.spacing(2)
    },
    popOver: {
        "& .MuiPaper-root": {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    divider: {
        borderTop: `1px solid ${theme.palette.text.primary}`,
        opacity: 0.32,
    },
    apply: {
        textAlign: 'right',
        marginRight: '15px',
        marginTop: '15px'
    },
    downloadAllTransactionButton: {
        width: theme.spacing(38)
    },
    downloadMusicPortfolioContent: {
        padding: 0
    },
    navContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: theme.palette.text.lightGray,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        // marginTop: '-55px'
    },
    searchTooltipContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateAlert: {
        color: theme.palette.primary.alert
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginLeft: theme.spacing(4),
        rowGap: '52.5px'
    },
    singleAccountHolderWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(4),
        alignItems: 'center'
    },
    accountHolder: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.primary,
        width: '190px'
    },
    singleAccountHolderLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
        width: '120px'
    },
    showOnly100oneHolder: {
        marginLeft: '18px',
        alignSelf: 'center'
    },
    filtersWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '16px',
        alignItems: 'center'
    },
    actionsButton: {
        textTransform: 'capitalize',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        borderRadius: '20px',
        height: '42px',
        width: '135px',
        textAlign: 'center',
        font: 'normal normal normal 14px / 17px Roboto',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    actionLink: {
        display: 'flex',
        gap: '8px',
        color: theme.palette.primary.contrastText
    }
}));

export const createAndClickFileDownloadLink = (file, fileName) => {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

const PortfolioPage = () => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const accountHolder = useState(0);
    const [accountHolders, setAccountHolders] = useState([]);

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [anchorEl, setAnchorEl] = useState(null);

    const territory = useState(false);
    const playSource = useState(false);
    const album = useState(false);
    const genre = useState(false);
    const reportingCompany = useState(false);

    const [groupings, setGroupings] = useState([]);
    const [downloadAllContentToastAlert, setDownloadAllContentToastAlert] = useState(false);
    const [downloadAllContentToastAlertMessage, setDownloadAllContentToastAlertMessage] = useState('');
    const [alert, setAlert] = useState(null);

    const [selectedFilter, setSelectedFilter] = useState('all');

    const [checkedUnasignedRevenueSplit, setCheckedUnasignedRevenueSplit] = useState(false);

    const [anchorElActions, setAnchorElActions] = useState(null);

    const [startQuarter, setStartQuarter] = useState(state.quarterPickerStartDate);
    const [endQuarter, setEndQuarter] = useState(Number(new Date().getFullYear() + `${Math.ceil((new Date().getMonth() + 1) / 3)}`));

    const [minDateValue, setMinDateValue] = useState(startQuarter);
    const [maxDateValue, setMaxDateValue] = useState(endQuarter);

    const isAdmin = state.user.userType !== userAccessByType.USER_TYPE_USER_ACCESS;

    useEffect(() => {
        setMinDateValue(startQuarter);
    }, [startQuarter])

    useEffect(() => {
        setMaxDateValue(endQuarter);
    }, [endQuarter])

    useEffect(() => {
        if (startQuarter > endQuarter) {
            setAlert('Start quarter is bigger than end quarter!')
        } else {
            setAlert(null)
        }
    }, [startQuarter, endQuarter])

    const handleClick = (event) => {
        setAnchorElActions(event.currentTarget);
    };

    const handleCloseActions = () => {
        setAnchorElActions(null);
    };

    const handleSelectUnasignedRevenue = (event) => {
        const { checked } = event.target;

        setCheckedUnasignedRevenueSplit(checked);
    }

    const abortableFetch = useAbortableFetch();

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setDownloadAllContentToastAlert(false);
    };

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => {
                setAccountHolders(data)
            });

        return () => {
            setState(state => (state.selectedSongPortfolioHeadFilter = '', { ...state }));
        }
    }, []);

    const onFilterOpenClick = (event) => {
        territory[1](groupings.includes('territory'));
        playSource[1](groupings.includes('playSource'));
        album[1](groupings.includes('album'));
        genre[1](groupings.includes('genre'));
        reportingCompany[1](groupings.includes('reportingCompany'));

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isDownloading, setIsDownloading] = useState(false);

    const downloadNftPortfolio = async () => {
        if (isDownloading) {
            return;
        }

        try {
            setIsDownloading(true);

            const transactions = await getData(process.env.REACT_APP_SERVER_HOST + '/api/nft/nft-report-data');
            const asCsvData = Papa.unparse(transactions);

            const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            const fileName = `nft_transactions_${dateStamp}.csv`;

            const csvFile = new File([asCsvData], fileName, {
                type: "text/plain",
            });

            createAndClickFileDownloadLink(csvFile, fileName);
        } catch (error) {
            console.log(error);
        } finally {
            setIsDownloading(false);
        }
    }

    const downloadMusicPortfolio = async () => {
        if (isDownloading) {
            return;
        }

        setIsDownloading(true);

        try {
            const body = {
                filter: search,
                startDate: state.navType === NavigationTypes.Music ? startDate : startQuarter,
                endDate: state.navType === NavigationTypes.Music ? endDate : endQuarter,
                accountHolder: accountHolder[0],
                groupings,
                checkedUnasignedRevenueSplit,
                selectedFilter
            };

            const fetchedData = await abortableFetch('POST', '/api/elastic/portfolio', {
                body
            });

            const dataToDownload = fetchedData.songsWithRevenues.map(item => {
                const {
                    displayName,
                    artistName,
                    accountHolderName,
                    releaseDate,
                    revenue,
                    totalMasterEarnings,
                    totalPublishingEarnings,
                    territory,
                    playSource,
                    album,
                    reportingCompany
                } = item;

                return {
                    'Song title': displayName,
                    'Artist': artistName,
                    'Account holders': accountHolderName.join(', '),
                    'Release date': releaseDate,
                    'Revenue': revenue ? formatNumber(revenue) : revenue,
                    'Total Master Earnings': totalMasterEarnings ? formatNumber(totalMasterEarnings) : totalMasterEarnings,
                    'Total Publisher Earnings': totalPublishingEarnings ? formatNumber(totalPublishingEarnings) : totalPublishingEarnings,
                    'Territory': territory,
                    'Play Source': playSource,
                    'Album': album,
                    'Reporting Company': reportingCompany
                };
            });

            const asCsvData = Papa.unparse(dataToDownload, { quotes: true });

            const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            const fileName = `portfolio-songs_${dateStamp}.csv`;

            const csvFile = new File([asCsvData], fileName, {
                type: 'text/plain'
            });

            createAndClickFileDownloadLink(csvFile, fileName);
        } catch (error) {
            console.log(error);
            setDownloadAllContentToastAlert(true);
            setDownloadAllContentToastAlertMessage(error.message);
        } finally {
            setIsDownloading(false);
        }
    };

    useEffect(() => {
        if (accountHolders && accountHolders.length === 1) {
            accountHolder[1](accountHolders[0].id)
        }
    }, [accountHolders])

    useEffect(() => {
        setState(state => (state.selectedAccountholderPortfolio = accountHolder[0], { ...state }));
    }, [accountHolder[0]])

    useEffect(() => {
        setSearch(state.selectedSongPortfolioHeadFilter);
    }, [state.selectedSongPortfolioHeadFilter])

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    return (
        <div className={styles.container}>
            <Snackbar open={downloadAllContentToastAlert} onClose={handleCloseAlert} >
                <Alert onClose={handleCloseAlert} severity="error">
                    {downloadAllContentToastAlertMessage}
                </Alert>
            </Snackbar>
            <div className={styles.title}>Portfolio</div>
            <div className={styles.selectsAndToolbarContainer}>
                <div className={styles.selectGroup}>
                    <div className={styles.defaultSelect}>
                        {
                            accountHolders && accountHolders.length < 2 ?
                                (
                                    <div className={styles.singleAccountHolderWrapper}>
                                        <div className={styles.singleAccountHolderLabel}>Account Holder: </div>
                                        <span className={styles.accountHolder}>
                                            {accountHolders[0]?.name ? accountHolders[0]?.name : 'N/A'}
                                        </span>
                                    </div>
                                )
                                :
                                <>
                                    <div className={styles.accountHolderLabel}>Account Holder</div>
                                    <BrandSelect $value={accountHolder}>
                                        <BrandMenuItem value={0}>All</BrandMenuItem>
                                        {accountHolders ? accountHolders.map(x => (
                                            <BrandMenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </BrandMenuItem>
                                        )) : null}
                                    </BrandSelect>
                                </>
                        }
                        <div className={styles.checkboxContainer}>
                            <div className={accountHolders && accountHolders.length < 2 ? styles.dateSelectOneAccHolder : styles.dateSelect}>
                                <div className={accountHolders && accountHolders.length < 2 ? styles.defaultSelectLabelOneAccHolder : styles.defaultSelectLabel}>Date Range</div>
                                {
                                    state.navType === NavigationTypes.Music ?
                                    <BrandDateRange startDate={startDate} setStartDate={(newValue) => setStartDate(newValue)} endDate={endDate} setEndDate={(newValue) => setEndDate(newValue)}/>
                                    :
                                    <>
                                        <QuarterPicker value={startQuarter} maxVal={maxDateValue} onChange={setStartQuarter} />
                                        <div className={styles.defaultSelectLabelTo}>to</div>
                                        <QuarterPicker value={endQuarter} minVal={minDateValue} onChange={setEndQuarter} />
                                    </> 
                                }
                                {
                                    state.user.userType === 1 && process.env.REACT_APP_SHOW_REVENUE_SPLIT === 'true' &&
                                    <div className={styles.showOnly100oneHolder}>
                                        <CustomCheckbox
                                            checked={checkedUnasignedRevenueSplit}
                                            onChange={handleSelectUnasignedRevenue}
                                        >
                                            Show only &#60;100% Assigned Revenue
                                        </CustomCheckbox>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            alert ?
                                <div className={styles.dateAlert}>{alert}</div>
                                :
                                null
                        }
                    </div>
                    <div>
                        <div className={styles.toolbar}>
                            <div style={{ display: 'inherit', width: '100%' }}>
                                <div className={styles.navContainer}>
                                    <div className={styles.filtersWrapper}>

                                        <div className={styles.searchTooltipContainer}>
                                            <BrandSearchInput
                                                placeholder={`Search for ${state.navType === 'Blockchain' ? 'Blockchain' : 'song'}`}
                                                value={search}
                                                onChange={(event) => setSearch(event.target.value)}
                                                classes={{ input: styles.searchInput }}
                                            />
                                            <BrandTooltipWithIcon title={ExactWordTooltipMessage} placement="bottom" />
                                        </div>
                                        {state.navType === NavigationTypes.Music && (
                                            <CustomFilterDropdown
                                                label='Filter songs by'
                                                selectedOption={selectedFilter}
                                                setSelectedOption={setSelectedFilter}
                                                options={[
                                                    { label: 'All', value: 'all' },
                                                    { label: 'Unverified', value: 'unverified' },
                                                    { label: 'Verified', value: 'verified' }
                                                ]}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.filtersWrapper}>
                                        {state.navType === NavigationTypes.Music && (
                                            <>
                                                <TempBrandButton capitalize={true} onClick={handleClick} disableTouchRipple>
                                                    +Action
                                                </TempBrandButton>
                                                <Popover
                                                    anchorEl={anchorElActions}
                                                    keepMounted
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    open={Boolean(anchorElActions)}
                                                    onClose={handleCloseActions}
                                                >
                                                    <MenuList>
                                                        {
                                                            isAdmin ?
                                                                <>
                                                                    <MenuItem>
                                                                        <Link
                                                                            className={styles.actionLink}
                                                                            component={NavLink}
                                                                            exact={true}
                                                                            to="/manage-shares"
                                                                        >
                                                                            <LibraryMusicIcon fontSize='small' />
                                                                            Manage Shares
                                                                        </Link>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <Link
                                                                            className={styles.actionLink}
                                                                            component={NavLink}
                                                                            exact={true}
                                                                            to="/upload"
                                                                        >
                                                                            <DescriptionIcon fontSize='small' />
                                                                            Upload CSV
                                                                        </Link>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <Link
                                                                            className={styles.actionLink}
                                                                            component={NavLink}
                                                                            exact={true}
                                                                            to="/revenue-split"
                                                                        >
                                                                            <BarChartIcon fontSize='small' />
                                                                            Revenue Split
                                                                        </Link>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <Link
                                                                            className={styles.actionLink}
                                                                            component={NavLink}
                                                                            exact={true}
                                                                            to="/generate-royalty-statement"
                                                                        >
                                                                            <MonetizationOnIcon fontSize='small' />
                                                                            Generate Royalty Statement
                                                                        </Link>
                                                                    </MenuItem>
                                                                </>
                                                                :
                                                                ''
                                                        }
                                                        <MenuItem>
                                                            <Link
                                                                className={styles.actionLink}
                                                                onClick={downloadMusicPortfolio}
                                                                disabled={isDownloading}
                                                            >
                                                                {
                                                                    isDownloading ?
                                                                        <BrandLoaderDots />
                                                                        :
                                                                        <>
                                                                            <VerticalAlignBottomIcon fontSize='small' />
                                                                            Download All Content
                                                                        </>
                                                                }
                                                            </Link>
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </>
                                        )}

                                        {state.navType === NavigationTypes.Music &&
                                            <>
                                                <IconButton className={styles.iconButton} onClick={onFilterOpenClick} disableTouchRipple>
                                                    <TuneIcon />
                                                </IconButton>
                                                <Popover
                                                    className={styles.popOver}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuList>
                                                        <Typography style={{ marginBottom: 10, marginTop: 10 }} variant="subtitle2" component='div'>
                                                            View Options
                                                        </Typography>
                                                        {/* <BrandCheckbox $value={territory} label='Territory' />
                                                        <br />
                                                        <BrandCheckbox $value={playSource} label='Play Source' />
                                                        <br /> */}
                                                        <BrandCheckbox $value={album} label='Album' />
                                                        <br />
                                                        <BrandCheckbox $value={genre} label='Genre' />
                                                        {/* <br />
                                                        <BrandCheckbox $value={reportingCompany} label='Reporting Company' /> */}
                                                        <Divider className={styles.divider} ></Divider>
                                                        <div className={styles.apply} >
                                                            <TempSecondaryBrandButton
                                                                size={ButtonSize.SMALL}
                                                                capitalize={false}
                                                                variant='text'
                                                                onClick={() => {
                                                                    setGroupings([
                                                                        territory[0] && 'territory',
                                                                        playSource[0] && 'playSource',
                                                                        album[0] && 'album',
                                                                        genre[0] && 'genre',
                                                                        reportingCompany[0] && 'reportingCompany'
                                                                    ].filter(x => x));
                                                                    handleClose()
                                                                }}
                                                            >
                                                                Apply
                                                            </TempSecondaryBrandButton>
                                                        </div>
                                                    </MenuList>
                                                </Popover>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {state.navType === 'Blockchain' && (
                                <TempBrandButton
                                    className={styles.downloadAllTransactionButton}
                                    onClick={downloadNftPortfolio}
                                    disabled={isDownloading}
                                >
                                    {isDownloading ? <BrandLoaderDots /> : 'Download All Transactions'}
                                </TempBrandButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {state.navType === NavigationTypes.Music ?
                <SongPortfolio
                    accountHolder={accountHolder}
                    startDate={startDate}
                    endDate={endDate}
                    search={searchQuery}
                    territory={territory}
                    playSource={playSource}
                    album={album}
                    genre={genre}
                    reportingCompany={reportingCompany}
                    groupings={groupings}
                    checkedUnasignedRevenueSplit={checkedUnasignedRevenueSplit}
                    selectedFilter={selectedFilter}
                />
                :
                state.navType === 'Blockchain' ?
                    <NftPortfolio
                        accountHolder={accountHolder}
                        startQuarter={startQuarter}     // Todo -> The Quarter picker should be replaced with the DateRangePicker at some point
                        endQuarter={endQuarter}         // Todo -> The Quarter picker should be replaced with the DateRangePicker at some point
                        search={searchQuery}
                    />
                    :
                    null
            }
        </div>
    );
}

export default PortfolioPage;