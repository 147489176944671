import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    backgroundColor: theme.palette.background.default,
    color: (props) =>
      props.type === TooltipType.error
        ? theme.palette.primary.alert
        : props.type === TooltipType.warning
        ? theme.palette.primary.warning
        : theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.lightYellow}`,
    borderRadius: '16px',

    textAlign: 'left',
    font: 'normal normal normal 16px/26px Roboto',
    padding: '12px',
  },
  tooltipArrow: {
    // Add stuff if needed
  }
}));

export const TooltipType = {
  info: 'info',
  warning: 'warning',
  error: 'error', 
}

/**
 * Wrapper around the Tooltip component that lets you change the tooltip style.
 * info -> green text
 * error -> red text
 * warning -> orange text
 *
 * @param {string} title - The text inside the tooltip.
 * @param {ReactNode} children - The stuff that triggers the tooltip.
 * @param {string} [type={TooltipType.info}] - Style of the tooltip. 
 * @param {...object} props - Any props you want to pass to the Tooltip component.
 *
 * @example
 * // Error tooltip example
 * <BrandTooltip title="Missing Data!" type={TooltipType.error} placement="bottom">
 *   <BrandButton>Error</BrandButton>
 * </BrandTooltip>
 */
export function BrandTooltip({ title, children, type = TooltipType.info, ...props }) {
  const styles = useStyles({type});
  return (
    <Tooltip title={title || ''} arrow classes={{
      tooltip: styles.tooltipText,
      arrow: styles.tooltipArrow,
    }} {...props}>
    <span>{children}</span>
  </Tooltip>
  );
};


/**
 * Wrapper that renders an info icon with a tooltip. 
 * You can set the tooltip's title and the size of the icon, as well as the tooltips' style.
 * 
 * @param {string} title - The text inside the tooltip.
 * @param {string} [iconSize='small'] - Icon size (small, medium, large).
 * @param {TooltipType} [type={TooltipType.info}] - Style of the tooltip (info, error, warning).
 * @param {...object} props - Any props you want to pass to the Tooltip component.
 * 
 * @example
 * // Basic usage with a medium-sized icon
 * <BrandTooltipWithIcon title="Warning!" size="medium" type={TooltipType.warning} placement="bottom" />
 *
 */
export function BrandTooltipWithIcon({ title, iconSize = 'small', type = TooltipType.info, ...props }) {
    const styles = useStyles({type});
    return (
        <Tooltip title={title} arrow classes={{
            tooltip: styles.tooltipText,
            arrow: styles.tooltipArrow,
          }} {...props}>
            <IconButton>
                <InfoOutlinedIcon fontSize={iconSize} />
            </IconButton>
        </Tooltip>
    );
};