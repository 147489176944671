import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { BrandDateRange } from '../CoreComponents/BrandDatePicker.js';

const useStyles = makeStyles((theme) => ({
    uploadedFilesTitle: {
        textOverflow: 'ellipsis',
        font: 'normal normal bold 10px/21px Roboto',
        letterSpacing: '0.5px',
        color: theme.palette.text.hint,
        textTransform: 'uppercase',
        opacity: 1,
        paddingLeft: '16px',
    },
    list: {
        maxHeight: '600px',
        overflow: 'auto',
        width: '450px',
        '&. MuiListItem-gutters': {
            padding: '0px',
        }
    },
    rootLI: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'start',
        '&. MuiListItem-gutters': {
            padding: '0px',
        }
    },
    rootLIContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'center',
        '&. MuiListItem-gutters': {
            padding: '0px',
        },
        '&. MuiListItemIcon-root': {
            minWidth: '40px',
        }
    },
    hr: {
        border: 'none',
        borderBottom: `1px solid ${theme.palette.primary.darkGrayNuance}`,
        marginTop: 10,
        marginBottom: 10,
    },
    fileIconWrapper: {
        minWidth: '40px',
    },
    fileIcon: {
        color: theme.palette.primary.lightGray,
    },
    deleteIcon: {
        color: theme.palette.primary.delete,
        cursor: 'pointer',
    },
    greenRadio: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.darkGreen,
        },
        '&.Mui-checked': {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.darkGreen,
            },
        },
    },
    radioButtonWrapper: {
        '& .MuiTypography-body1': {
            font: 'normal normal medium 14px/22px Roboto',
            fontSize: '0.8rem'
        },
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    leftPadding: {
        paddingLeft: '16px',
    },
}));

const csvDateOptions = {
    DatePeriodFromCsv: 'Date Period from CSV',
    CustomDatePeriod: 'Custom Date Period'
}

const UploadFilesList = ({ uploadedFiles, setUploadedFiles }) => {
    const styles = useStyles();
    
    // A map that kepps track of what date option is selected for each individual csv  
    const [selectedOptions, setSelectedOptions] = useState({}); 
    const MAX_FILENAME_LENGTH = 25;

    useEffect(() => { // If a new file is appended, add a default entry in selectedOptions for it
        uploadedFiles.forEach(file => {
            if (!selectedOptions.hasOwnProperty(file.path)) {
                setSelectedOptions(prevOptions => ({
                    ...prevOptions,
                    [file.path]: csvDateOptions.DatePeriodFromCsv
                }));
            }
        })
    }, [uploadedFiles])

    useEffect(() => { // Set the correct option on first render
        const initialOptions = uploadedFiles.reduce((options, file) => {
            options[file.path] = file.customDatePeriod ? csvDateOptions.CustomDatePeriod : csvDateOptions.DatePeriodFromCsv;
            return options;
        }, {});

        setSelectedOptions(initialOptions);
    }, []); 

    const handleOptionChange = (e, index) => { // Change Radio button option
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [index]: e.target.value
        }));
    };

    const handleFileDelete = (index) => { // Handle deleting a file
        setSelectedOptions(prevOptions => {
            const newSelectedOptions = { ...prevOptions };
            delete newSelectedOptions[index];
            return newSelectedOptions;
        });
    };
    
    return (
        <>
            <div className={styles.uploadedFilesTitle}>Uploaded File(s)</div>
            <List className={styles.list}>
                {uploadedFiles.map((file, index) => {
                    let label = file.path;
                    if (label.length >= MAX_FILENAME_LENGTH) {
                        label = label.slice(0, MAX_FILENAME_LENGTH) + '...'
                    }

                    const DATE_CHANGE_TYPES = {
                        START: 'start',
                        END: 'end',
                        RESET: 'reset',
                    };                    

                    // Type tells us wether to change the startDate, endDate, or to let the csv handle the period.
                    const handleDateChange = (type, value, index) => {
                        setUploadedFiles(prevState =>
                            prevState.map((item, fileIndex) =>
                                fileIndex === index
                                    ? {
                                        ...item,
                                        startDate: type === DATE_CHANGE_TYPES.START ? value : type === DATE_CHANGE_TYPES.RESET ? undefined : item.startDate,
                                        endDate: type === DATE_CHANGE_TYPES.END ? value : type === DATE_CHANGE_TYPES.RESET ? undefined : item.endDate,
                                        customDatePeriod: type !== DATE_CHANGE_TYPES.RESET,
                                    }
                                    : item
                            )
                        );
                    };                    

                    const removeFile = () => {
                        setUploadedFiles(uploadedFiles.filter(x => x !== file));
                        handleFileDelete(file.path);
                    }

                    return (
                        <div key={index}>
                            <ListItem className={styles.rootLI}>
                                <div className={styles.rootLIContent}>
                                    <ListItemIcon className={styles.fileIconWrapper}>
                                        <DescriptionOutlinedIcon className={styles.fileIcon} />
                                    </ListItemIcon>
                                    <Tooltip placement='bottom' title={file.path}>
                                        <ListItemText primary={label}/>
                                    </Tooltip>
                                    <ListItemIcon onClick={removeFile} className={styles.leftPadding}>
                                        <DeleteOutlineOutlinedIcon className={styles.deleteIcon} />
                                    </ListItemIcon>
                                </div>
                                <Box className={styles.radioButtonWrapper}>
                                    <RadioGroup
                                        value={selectedOptions[file.path] || csvDateOptions.DatePeriodFromCsv}
                                        onChange={(e) => handleOptionChange(e, file.path)}
                                        row
                                        >
                                        <FormControlLabel
                                            className={styles.radioButtonLabel} 
                                            value={csvDateOptions.DatePeriodFromCsv} 
                                            control={<Radio className={styles.greenRadio}/>} 
                                            label={csvDateOptions.DatePeriodFromCsv}
                                            onClick={() => handleDateChange(DATE_CHANGE_TYPES.RESET, null, index)}
                                            />
                                        <FormControlLabel 
                                            className={styles.radioButtonLabel} 
                                            value={csvDateOptions.CustomDatePeriod}  
                                            control={<Radio className={styles.greenRadio}/>} 
                                            label={csvDateOptions.CustomDatePeriod}
                                            />
                                    </RadioGroup>
                                    {
                                        selectedOptions[file.path] == csvDateOptions.CustomDatePeriod &&
                                        <BrandDateRange
                                        startDate={uploadedFiles[index].startDate} 
                                        setStartDate={(newStartDate) => {handleDateChange(DATE_CHANGE_TYPES.START, newStartDate, index);}} 
                                        endDate={uploadedFiles[index].endDate} 
                                        setEndDate={(newEndDate) => {handleDateChange(DATE_CHANGE_TYPES.END, newEndDate, index);}} 
                                        />
                                    }
                                </Box>
                            </ListItem>
                            {index !== uploadedFiles.length - 1 ?
                                <hr className={styles.hr} />
                                :
                                null
                            }
                        </div>
                    )
                })}
            </List>
        </>
    );
}

export default UploadFilesList;