import React, { useState, useEffect } from "react";
import { ExpandMore } from "@material-ui/icons";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Box, InputAdornment, Typography, Divider, Popover, Button, makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate, isLeapYear, parseDate } from '../utils/DateUtils'
import clsx from 'clsx';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { useStoreContext } from '../../store/Store';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: '100%',
        '& .MuiFormControl-root': {
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
        },
        "& .Mui-focused": {
            color: "white"
        },
        "& .MuiInputBase-root": {
            '&::before': {
                content: 'none'
            },
            '&::after': {
                content: 'none'
            },
            width: '100%',
            height: theme.spacing(5),
            borderRadius: theme.spacing(2.5),
            boxSizing: 'border-box',
            padding: '8px 16px',
            background: '#000000',
            color: 'white',
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
        }
    },
    sus: {
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent'
        },
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: 'transparent'
        }
    }
}))

export function BrandDatePicker({ label = 'Choose Date', selectedDate, setSelectedDate, maxDate, minDate }) {
    const styles = useStyles();
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        maxDate && minDate ?
            <div className={styles.datePicker}>
                {label ?
                    <Typography
                        component='div'
                        variant='subtitle2'
                    >
                        {label}
                    </Typography>
                    :
                    null
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ExpandMoreIcon />
                                </InputAdornment>
                            )
                        }}
                        minDate={parseDate(minDate)}
                        maxDate={parseDate(maxDate)}
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                        maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                    />
                </MuiPickersUtilsProvider>
            </div >
            :
            maxDate ?
                <div className={styles.datePicker}>
                    {label ?
                        <Typography
                            component='div'
                            variant='subtitle2'
                        >
                            {label}
                        </Typography>
                        :
                        null
                    }
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon />
                                    </InputAdornment>
                                )
                            }}
                            maxDate={parseDate(maxDate)}
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            value={selectedDate}
                            onChange={handleDateChange}
                            maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                :
                minDate ?
                    <div className={styles.datePicker}>
                        {label ?
                            <Typography
                                component='div'
                                variant='subtitle2'
                            >
                                {label}
                            </Typography>
                            :
                            null
                        }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>
                                    )
                                }}
                                minDate={parseDate(minDate)}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                            />
                        </MuiPickersUtilsProvider>
                    </div >
                    :
                    <>
                        <div className={styles.datePicker}>
                            {label ?
                                <Typography
                                    component='div'
                                    variant='subtitle2'
                                >
                                    {label}
                                </Typography>
                                :
                                null
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ExpandMoreIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div >
                    </>
    );
}

const useBrandDateRangeStyles = makeStyles((theme) => ({
    button: {
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
        width: '275px',
        textTransform: 'capitalize'
    },
    buttonIcon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    },
    popoverContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '940px',
        border: `1px solid ${theme.palette.primary.border}`,
        boxShadow: `0px 3px 6px ${theme.palette.background.default}`,
        borderRadius: '6px',
        '& .rdp-root': {
            padding: '10px 20px',
            '--rdp-accent-color': theme.palette.primary.main,
            '--rdp-range_start-color': theme.palette.text.black,
            '--rdp-range_end-color': theme.palette.text.black,
            '& .rdp-selected .rdp-day_button': {
                background: 'var(--rdp-accent-color)',
                color: theme.palette.text.black
            }
        }
    },
    divider: {
        backgroundColor: theme.palette.primary.border,
    },
    radioGroup: {
        justifyContent: 'space-between',
        width: '100%',
    },
    filtersWrapper: {
        padding: theme.spacing(1),
    },
    filterLabel: {
        border: `1px solid ${theme.palette.primary.border}`,
        transition: 'border-color 0.7s',
        padding: '6px 0px 6px 0px',
        marginLeft: '1px',
        borderRadius: '6px',
        display: 'flex',
        flexGrow: 1,
        width: '23%',
        '&:hover': {
            borderColor: theme.palette.primary.main
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.9rem',
        },
    },
    selectedFilter: {
        borderColor: theme.palette.primary.main,
    },
    greenRadio: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.darkGreen,
        },
        '&.Mui-checked': {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.darkGreen,
            },
        },
    },
    datesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '371px',
    },
    filterOptionsWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    option: {
        textTransform: 'capitalize',
        padding: '10px',
        marginTop: '8px',
        marginBottom: '2px',
        '&.MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            '&.Mui-disabled': {
                color: theme.palette.primary.darkGray,
            },
        },
    },
    selectedBackground: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.black,
        transition: 'background-color 0.5s ease, color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease',
        '&:hover': {
            color: theme.palette.text.primary,
            transform: 'scale(1.1)',
        }
    },
    tooltipText: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.alert,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',

        textAlign: 'left',
        font: 'normal normal normal 16px/26px Roboto',
        padding: '12px',
    }
}));

const JANUARY = 0;
const FEBRUARY = 1;
const MARCH = 2;
const APRIL = 3;
const MAY = 4;
const JUNE = 5;
const JULY = 6;
const AUGUST = 7;
const SEPTEMBER = 8;
const OCTOBER = 9;
const NOVEMBER = 10;
const DECEMBER = 11;
const START_OF_MONTH_DAY = 1;
const END_OF_MONTH_DAY = 31;
const DEFAULT_BUTTON_LABEL = 'Select Date';

const DateFilters = {
    CustomPeriodRange: 'Custom Period Range',
    SemiAnnual: 'Semi-Annual',
    Quarter: 'Quarter',
    Month: 'Month',
};

const FilterOptions = {
    CustomPeriodRange: {
        AllTime: 'All Time',
        LastQuarter: 'Last Quarter',
        LastYear: 'Last Year',
    },
    SemiAnnual: {
        H1: 'H1 (1-6 months)',
        H2: 'H2 (7-12 months)',
    },
    Quarter: {
        Q1: 'Q1',
        Q2: 'Q2',
        Q3: 'Q3',
        Q4: 'Q4',
    },
    Month: {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December',
    },
};

export function BrandDateRange({ startDate, setStartDate, endDate, setEndDate, setPeriod = null }) {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const styles = useBrandDateRangeStyles();

    const [store] = useStoreContext();
    const [endMonth, setEndMonth] = useState(today);
    const [anchorEl, setAnchorEl] = useState(null);
    const [startYear, setStartYear] = useState(currentYear);
    const [startMonth, setStartMonth] = useState(today);
    const [buttonLabel, setButtonLabel] = useState(DEFAULT_BUTTON_LABEL);
    const [selectedFilter, setSelectedFilter] = useState(DateFilters.CustomPeriodRange);
    const [dateRangeOption, setDateRangeOption] = useState(null);
    const normalize = (str) => str && str.trim().toLowerCase();

    const monthLengths = {
        Jan: END_OF_MONTH_DAY,
        Feb: (year) => isLeapYear(year) ? 29 : 28,
        Mar: END_OF_MONTH_DAY,
        Apr: END_OF_MONTH_DAY - 1,
        May: END_OF_MONTH_DAY,
        June: END_OF_MONTH_DAY - 1,
        July: END_OF_MONTH_DAY,
        Aug: END_OF_MONTH_DAY,
        Sep: END_OF_MONTH_DAY - 1,
        Oct: END_OF_MONTH_DAY,
        Nov: END_OF_MONTH_DAY - 1,
        Dec: END_OF_MONTH_DAY,
    };

    const getMonthEndDate = (year, month) => {
        const monthName = Object.keys(monthLengths)[month];
        const monthLength = monthLengths[monthName];
        return typeof monthLength === 'function' ? monthLength(year) : monthLength;
    };

    const getLastQuarter = () => {
        // Last month of the quarter
        const quarterEndMonth = (currentMonth - 1 + 12) % 12; 
        // Start is 2 months before the end
        const quarterStartMonth = (quarterEndMonth - 2 + 12) % 12; 

        const quarterStartYear = quarterEndMonth < currentMonth ? currentYear : currentYear - 1;
        const quarterEndYear = quarterEndMonth < currentMonth ? currentYear : currentYear - 1;

        return {
            start: {
                year: quarterStartYear,
                month: quarterStartMonth,
                day: START_OF_MONTH_DAY,
            },
            end: {
                year: quarterEndYear,
                month: quarterEndMonth,
                day: getMonthEndDate(quarterEndYear, quarterEndMonth),
            },
        };
    };

    const dateConfig = {
        // Custom Filters
        [normalize(FilterOptions.CustomPeriodRange.AllTime)]:     { start: { year: store.defaultDateRangeStartDate.getFullYear(), month: store.defaultDateRangeStartDate.getMonth(), day: store.defaultDateRangeStartDate.getDate() }, 
                                                                                                                                                    end: { year: currentYear, month: today.getMonth(), day: today.getDate() } },
        [normalize(FilterOptions.CustomPeriodRange.LastQuarter)]: getLastQuarter(),
        [normalize(FilterOptions.CustomPeriodRange.LastYear)]:    { start: { year: currentYear - 1, month: JANUARY, day: START_OF_MONTH_DAY },      end: { year: currentYear - 1, month: DECEMBER, day: END_OF_MONTH_DAY } },
        
        //Semi-Annual
        [normalize(FilterOptions.SemiAnnual.H1)]: { start: { year: startYear, month: JANUARY, day: START_OF_MONTH_DAY },end: { year: startYear, month: JUNE, day: getMonthEndDate(startYear, JUNE) } },
        [normalize(FilterOptions.SemiAnnual.H2)]: { start: { year: startYear, month: JULY, day: START_OF_MONTH_DAY },   end: { year: startYear, month: DECEMBER, day: getMonthEndDate(startYear, DECEMBER) } },
    
        // Quarters
        [normalize(FilterOptions.Quarter.Q1)]: { start: { year: startYear, month: JANUARY, day: START_OF_MONTH_DAY },   end: { year: startYear, month: MARCH, day: getMonthEndDate(startYear, MARCH) } },
        [normalize(FilterOptions.Quarter.Q2)]: { start: { year: startYear, month: APRIL, day: START_OF_MONTH_DAY },     end: { year: startYear, month: JUNE, day: getMonthEndDate(startYear, JUNE) } },
        [normalize(FilterOptions.Quarter.Q3)]: { start: { year: startYear, month: JULY, day: START_OF_MONTH_DAY },      end: { year: startYear, month: SEPTEMBER, day: getMonthEndDate(startYear, SEPTEMBER) } },
        [normalize(FilterOptions.Quarter.Q4)]: { start: { year: startYear, month: OCTOBER, day: START_OF_MONTH_DAY },   end: { year: startYear, month: DECEMBER, day: getMonthEndDate(startYear, DECEMBER) } },
    
        // Months
        [normalize(FilterOptions.Month.Jan)]: { start: { year: startYear, month: JANUARY, day: START_OF_MONTH_DAY },    end: { year: startYear, month: JANUARY, day: getMonthEndDate(startYear, JANUARY) } },
        [normalize(FilterOptions.Month.Feb)]: { start: { year: startYear, month: FEBRUARY, day: START_OF_MONTH_DAY },   end: { year: startYear, month: FEBRUARY, day: getMonthEndDate(startYear, FEBRUARY) } },
        [normalize(FilterOptions.Month.Mar)]: { start: { year: startYear, month: MARCH, day: START_OF_MONTH_DAY },      end: { year: startYear, month: MARCH, day: getMonthEndDate(startYear, MARCH) } },
        [normalize(FilterOptions.Month.Apr)]: { start: { year: startYear, month: APRIL, day: START_OF_MONTH_DAY },      end: { year: startYear, month: APRIL, day: getMonthEndDate(startYear, APRIL) } },
        [normalize(FilterOptions.Month.May)]: { start: { year: startYear, month: MAY, day: START_OF_MONTH_DAY },        end: { year: startYear, month: MAY, day: getMonthEndDate(startYear, MAY) } },
        [normalize(FilterOptions.Month.Jun)]: { start: { year: startYear, month: JUNE, day: START_OF_MONTH_DAY },       end: { year: startYear, month: JUNE, day: getMonthEndDate(startYear, JUNE) } },
        [normalize(FilterOptions.Month.Jul)]: { start: { year: startYear, month: JULY, day: START_OF_MONTH_DAY },       end: { year: startYear, month: JULY, day: getMonthEndDate(startYear, JULY) } },
        [normalize(FilterOptions.Month.Aug)]: { start: { year: startYear, month: AUGUST, day: START_OF_MONTH_DAY },     end: { year: startYear, month: AUGUST, day: getMonthEndDate(startYear, AUGUST) } },
        [normalize(FilterOptions.Month.Sep)]: { start: { year: startYear, month: SEPTEMBER, day: START_OF_MONTH_DAY },  end: { year: startYear, month: SEPTEMBER, day: getMonthEndDate(startYear, SEPTEMBER) } },
        [normalize(FilterOptions.Month.Oct)]: { start: { year: startYear, month: OCTOBER, day: START_OF_MONTH_DAY },    end: { year: startYear, month: OCTOBER, day: getMonthEndDate(startYear, OCTOBER) } },
        [normalize(FilterOptions.Month.Nov)]: { start: { year: startYear, month: NOVEMBER, day: START_OF_MONTH_DAY },   end: { year: startYear, month: NOVEMBER, day: getMonthEndDate(startYear, NOVEMBER) } },
        [normalize(FilterOptions.Month.Dec)]: { start: { year: startYear, month: DECEMBER, day: START_OF_MONTH_DAY },   end: { year: startYear, month: DECEMBER, day: getMonthEndDate(startYear, DECEMBER) } },
    };

    const getDateRange = (filterOption, updateDates = true) => {
        const config = dateConfig[normalize(filterOption)];
        if (!config) { return null; }
        if (setPeriod && updateDates) {
            if (selectedFilter !== DateFilters.CustomPeriodRange){
                setPeriod(`${filterOption}, ${config.start.year}`);
            }
            else {
                setPeriod(filterOption);
            }
        }
        const startDate = new Date(config.start.year ? config.start.year : currentYear, config.start.month, config.start.day);

        let endDate;
        if (currentMonth === config.end.month && currentYear === config.end.year) {
            endDate = new Date(currentYear, currentMonth, today.getDate());
        } else {
            endDate = new Date(config.end.year ? config.end.year : currentYear, config.end.month, config.end.day);
        }

        if (updateDates && endDate <= today) {
            setStartDate(startDate);
            setEndDate(endDate);
            setStartYear(endDate.getFullYear());
        }
        return endDate;
    };

    useEffect(() => {
        if (!dateRangeOption){ return; }
        getDateRange(dateRangeOption);

        if (isDateOptionDisabled(dateRangeOption)) {
            setDateRangeOption(null);
        }

    }, [dateRangeOption, startYear]);

    useEffect(() => {
        setStartMonth(startDate ? startDate : today);
        setEndMonth(endDate ? endDate : today);
        if (startDate && endDate) {
            setButtonLabel(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        } else {
            setButtonLabel(DEFAULT_BUTTON_LABEL);
        }
    }, [startDate, endDate]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSelectDateRangeOption = (e) => {
        const newDateRangeOption = e.target.innerText;
        setDateRangeOption(dateRangeOption === newDateRangeOption ? null : newDateRangeOption);
    }

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    }

    const isDateOptionDisabled = (dateOption) => {
        const normalizedOption = normalize(dateOption);
        const endDate = getDateRange(normalizedOption, false);
        if(endDate.getMonth() === currentMonth && endDate.getFullYear() === currentYear) {
            return false;
        } 
        return today <= endDate;
    };

    const onStartDateSelect = (newStartDate) => {
        setStartDate(newStartDate);
        if(setPeriod) { setPeriod('Custom') }
    }

    const onEndDateSelect = (newEndDate) => {
        setEndDate(newEndDate);
        if(setPeriod) { setPeriod('Custom') }
    }

    const renderFilterOptions = () => {
        let options;
        switch (selectedFilter) {
            case DateFilters.CustomPeriodRange: options = FilterOptions.CustomPeriodRange; break;
            case DateFilters.SemiAnnual:        options = FilterOptions.SemiAnnual; break;
            case DateFilters.Quarter:           options = FilterOptions.Quarter; break;
            case DateFilters.Month:             options = FilterOptions.Month; break;
            default:                            options = null;
        }

        if(!options) {
            return null;
        }

        const lowerHeight = selectedFilter === DateFilters.Quarter;
        return (
            <Box style={{ height: lowerHeight ? '180px' : '371px' }} className={styles.filterOptionsWrapper}>
                {Object.entries(options).map(([key, label]) => {
                    const isOptionDisabled = isDateOptionDisabled(label);
                    return (
                        <Tooltip
                            title={isOptionDisabled ? "You can't select a date that hasn't happened yet." : ''}
                            disableInteractive
                            disableHoverListener={false}
                            classes={{ tooltip: styles.tooltipText }}>
                            <span>
                                <Button
                                    className={clsx(styles.option, normalize(dateRangeOption) === normalize(label) ? styles.selectedBackground : null)}
                                    onClick={handleSelectDateRangeOption}
                                    disabled={isOptionDisabled}
                                    key={key}
                                >
                                    {label}
                                </Button>
                            </span>
                        </Tooltip>
                    )
                })}
            </Box>
        );
    }

    const onLeftCalendarMonthChange = (month) => {
        if (selectedFilter && selectedFilter === DateFilters.CustomPeriodRange) {
            setDateRangeOption(null);
        }
        setStartMonth(month)
        setStartYear(month.getFullYear());
    }

    return (
        <div>
            <Button className={styles.button} onClick={handleClick}>
                {buttonLabel}
                <ExpandMore fontSize='large' className={styles.buttonIcon} />
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box className={styles.popoverContentWrapper}>
                    <FormControl className={styles.filtersWrapper}>
                        <RadioGroup
                            defaultValue={selectedFilter ? selectedFilter : DateFilters.CustomPeriodRange}
                            aria-labelledby="date-filters-group"
                            className={styles.radioGroup}
                            onChange={handleFilterChange}
                            name="radio-buttons-group"
                            row
                        >
                            {Object.entries(DateFilters).map(([key, label]) => (
                                <FormControlLabel
                                    key={key}
                                    className={`${styles.filterLabel} ${selectedFilter === label ? styles.selectedFilter : ''}`}
                                    value={label}
                                    control={<Radio className={styles.greenRadio} />}
                                    label={label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Divider className={styles.divider} />
                    <Box className={styles.datesWrapper}>
                        <DayPicker
                            month={startMonth} // Previewed date
                            startMonth={store.defaultDateRangeStartDate}
                            onMonthChange={onLeftCalendarMonthChange}
                            mode="single"
                            captionLayout="dropdown"
                            selected={startDate} // Selected date
                            onSelect={onStartDateSelect}
                            disabled={{ after: endDate < today ? endDate : today }}
                        />
                        <Divider orientation='vertical' flexItem className={styles.divider} />
                        <DayPicker
                            month={endMonth} // Previewed date
                            startMonth={store.defaultDateRangeStartDate}
                            onMonthChange={(month) => setEndMonth(month)}
                            mode="single"
                            captionLayout="dropdown"
                            selected={endDate} // Selected date
                            onSelect={onEndDateSelect}
                            disabled={{ before: startDate, after: today }}
                        />
                        <Divider orientation='vertical' flexItem className={styles.divider} />
                        {renderFilterOptions()}
                    </Box>
                </Box>
            </Popover>
        </div>
    )
}