import { Box, Divider, Typography, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import Switch from "./Switch";
import SongsTable from "./SongsTable";
import { getData } from "../utils/FetchUtils";
import AccountHolderAutocomplete from "./AccountHolderAutocomplete";
import SongsAutocomplete from "./Autocompletes/SongsAutocomplete";
import CountriesAutocomplete from "./Autocompletes/CountriesAutocomplete";
import PlaySourcesAutocomplete from "./Autocompletes/PlaySourcesAutocomplete";
import ReportingCompaniesAutocomplete from "./Autocompletes/ReportingCompaniesAutocomplete";
import SunburstChart from "./SunburstChart";
import { createAndClickFileDownloadLink } from "../Portfolio/PortfolioPage";
import * as Papa from 'papaparse';
import { BrandLoaderDots } from "../CoreComponents/BrandLoader";
import { BrandPopover } from "../CoreComponents/BrandPopover";
import clsx from 'clsx';
import { LOCALE_EN_CA } from "../utils/DateUtils";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { EARNINGS_DEFAULT_ROWS_PER_PAGE, EARNINGS_ROWS_PER_PAGE_OPTIONS } from "../utils/PaginationUtils";
import { BrandButton, TempBrandButton } from "../CoreComponents/BrandButton";
import { BrandDateRange } from "../CoreComponents/BrandDatePicker";


export const formatNumber = (element) => {
    return Number(element).toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
}
export const formatInteger = (element) => {
    return Number(element).toLocaleString('en');
}

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: '85%',
        margin: '0 auto',
        [theme.breakpoints.between(1, 1400)]: {
            width: '95%',
        },
    },
    headerAndSelectWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: '40px'
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        marginTop: '30px',
    },
    header: {
        fontWeight: '600'
    },
    categoriesRevenueWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    revenueWrapper: {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.background.lime}`,
        width: '270px',
        borderRadius: '5px',
        color: theme.palette.background.lime,
        alignItems: 'center',
        padding: '10px 0px',
        height: '80px',
        justifyContent: 'center',
        [theme.breakpoints.between(1, 1276)]: {
            height: '105px',
        },
    },
    categoriesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        width: '75%',
        [theme.breakpoints.between(1, 1400)]: {
            width: '70%',
        },
    },
    categoriesContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.grayNuance,
        alignItems: 'center',
        justifyContent: 'space-around',
        minHeight: '80px',
    },
    categoryWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 40px',
        minWidth: '100px'
    },
    dividerLine: {
        height: '60px',
        backgroundColor: 'gray'
    },
    categoryNumber: {
        fontWeight: '600',
        fontSize: '18px'
    },
    pieChartButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '15px',
        alignItems: 'center',
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '70px',
        justifyContent: 'flex-end',
        marginTop: '40px'
    },
    downloadButton: {
        width: '195px'
    },
    button: {
        padding: '0px 20px !important',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        width: '255px',
        textTransform: 'capitalize'
    },
    buttonIcon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    },
    buttonWithLabelWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        alignItems: 'center',
        '& .MuiButton-label': {
            height: '35px'
        }
    },
    topSideButtonsBeforeSelect: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: '15%',
        marginTop: '50px'
    },
    topSideButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        columnGap: '15%',
        width: '70%'
    },
    leftSideButtons: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
    },
    resetBtnAndFiltersHeaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    resetBtn: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    buttonsDiagramWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    songsTableWrapper: {
        display: 'flex',
        width: '75%',
        flexDirection: 'column',
        [theme.breakpoints.between(1, 1400)]: {
            width: '70%',
        },
    },
    searchIcon: {
        marginRight: '10px'
    },
    calendar: {
        backgroundColor: '#1B1B1B;',
        '& .Mui-selected': {
            color: 'white',
            backgroundColor: `${theme.palette.background.darkGreen} !important`
        },
        '& .MuiButtonBase-root, & .MuiTypography-root': {
            color: 'white'
        }
    },
    loaderDots: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    beforeSelecting: {
        marginRight: '520px'
    },
    revenueNumberWarapper: {
        display: 'flex',
        alignItems: 'center'
    },
    currencySign: {
        marginRight: theme.spacing(0.2)
    }
}));

const dummyCategories = [
    { category: 'Countries', value: '128' },
    { category: 'Songs', value: '235' },
    { category: 'Royalty types', value: '2' },
    { category: 'Play Sources', value: '8' },
    { category: 'Reporting companies', value: '90' }
];

const Earnings = () => {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const [showChart, setShowChart] = useState(false);

    const [categoriesData, setCategoriesData] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(false);

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    
    const [selectedRange, setSelectedRange] = useState('All Time');
    const [selectedAccountHolders, setSelectedAccountHolders] = useState([]);
    const [lessThanTwoAccountholders, setLessThanTwoAccountholders] = useState(false);
    const [selectedSongs, setSelectedSongs] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedPlaySources, setSelectedPlaySources] = useState([]);
    const [selectedReportingCompanies, setSelectedReportingCompanies] = useState([]);
    const [bodyData, setBodyData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [loadingChart, setLoadingChart] = useState(false);

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [loadingRevenue, setLoadingRevenue] = useState(false);

    const [loadingTable, setLoadingTable] = useState(false);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const [sortingType, setSortingType] = useState('desc');
    const [rowsPerPage, setRowsPerPage] = useState(EARNINGS_DEFAULT_ROWS_PER_PAGE);

    const [isDownloading, setIsDownloading] = useState(false);
    const [disableAccountHolderAutoComplete, setDisableAccountHolderAutoComplete] = useState(false);

    const maxVisibleChars = 18;
    const earningsTotalRevenue = formatNumber(totalRevenue);
    const formatedEarningsTotalRevenue = earningsTotalRevenue.length > maxVisibleChars ? `${earningsTotalRevenue.substring(0, maxVisibleChars + 1)}...` : earningsTotalRevenue;

    useEffect(() => {
        (async () => {
            try {
                const query = `/api/earnings/account-holders?offset=0&limit=10&filter=`;
                const { items } = await getData(process.env.REACT_APP_SERVER_HOST + query);

                if (items.length === 1) {
                    setSelectedAccountHolders(items);
                    setLessThanTwoAccountholders(true);
                } else if (items.length === 0){
                    setLessThanTwoAccountholders(true);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [])

    const handleLoadCategories = async () => {
        setLoadingCategories(true);

        let startDateString = undefined, endDateString = undefined;
        if (startDate && endDate) {
            startDateString = startDate.toLocaleDateString(LOCALE_EN_CA);
            endDateString = endDate.toLocaleDateString(LOCALE_EN_CA);
        }

        try {
            const { categories } = await abortableFetch('POST', '/api/elastic/earnings-categories', {
                body: {
                    startDate: startDateString,
                    endDate: endDateString,
                    accountHolders: selectedAccountHolders.map((a) => a.id)
                }
            });

            setCategoriesData(categories);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingCategories(false);
        }
    }
    useEffect(() => {
        if (selectedAccountHolders.length === 0) return;

        handleLoadCategories();
    }, [selectedAccountHolders, startDate, endDate]);

    const handleLoadingTable = async () => {
        setLoadingTable(true);
        setLoadingRevenue(true);
        setDisableAccountHolderAutoComplete(true);

        let startDateString = undefined, endDateString = undefined;
        if (startDate && endDate) {
            startDateString = startDate.toLocaleDateString(LOCALE_EN_CA);
            endDateString = endDate.toLocaleDateString(LOCALE_EN_CA);
        }


        try {
            const { songsWithRevenues, songsCount, totalRevenue } = await abortableFetch('POST', '/api/elastic/earnings-table', {
                query: {
                    offset,
                    limit: rowsPerPage
                },
                body: {
                    startDate: startDateString,
                    endDate: endDateString,
                    sortingType,
                    songs: selectedSongs.map((s) => s.id),
                    accountHolders: selectedAccountHolders.map((a) => a.id),
                    countries: selectedCountries.map((c) => c.name === 'All countries selected' ? 'All countries selected' : c.name),
                    playSources: selectedPlaySources.map((p) => p.name === 'All play sources selected' ? 'All play sources selected' : p.name),
                    reportingCompanies: selectedReportingCompanies.map((r) => r.id === "" ? 'Select All' : r.id)
                }
            });

            setTotalRevenue(totalRevenue);
            setBodyData(songsWithRevenues);
            setRowsCount(songsCount);
            setDisableAccountHolderAutoComplete(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingTable(false);
            setLoadingRevenue(false);
        }
    }

    const handleLoadingChart = async () => {
        setLoadingChart(true);

        let startDateString = undefined, endDateString = undefined;
        if (startDate && endDate) {
            startDateString = startDate.toLocaleDateString(LOCALE_EN_CA);
            endDateString = endDate.toLocaleDateString(LOCALE_EN_CA);
        }

        try {
            const { songsWithRevenues } = await abortableFetch('POST', '/api/elastic/earnings-chart', {
                body: {
                    startDate: startDateString,
                    endDate: endDateString,
                    songs: selectedSongs.map((s) => s.id),
                    accountHolders: selectedAccountHolders.map((a) => a.id),
                    countries: selectedCountries.map((c) => c.name === 'All countries selected' ? 'All countries selected' : c.name),
                    playSources: selectedPlaySources.map((p) => p.name === 'All play sources selected' ? 'All play sources selected' : p.name),
                    reportingCompanies: selectedReportingCompanies.map((r) => r.id === "" ? 'Select All' : r.id)
                }
            });

            setChartData(songsWithRevenues);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingChart(false);
        }
    }

    useEffect(() => {
        if (selectedAccountHolders.length === 0) return;

        handleLoadingTable();
    }, [selectedAccountHolders, selectedSongs, selectedCountries, selectedPlaySources, selectedReportingCompanies, offset, startDate, endDate, sortingType, rowsPerPage]);

    useEffect(() => {
        if (selectedAccountHolders.length === 0) return;

        handleLoadingChart();
    }, [selectedAccountHolders, selectedSongs, selectedCountries, selectedPlaySources, selectedReportingCompanies, startDate, endDate]);

    const downloadContent = async () => {
        setIsDownloading(true);

        let startDateString = undefined, endDateString = undefined;
        if (startDate && endDate) {
            startDateString = startDate.toLocaleDateString(LOCALE_EN_CA);
            endDateString = endDate.toLocaleDateString(LOCALE_EN_CA);
        }

        try {
            const { songsEarnings } = await abortableFetch('POST', '/api/elastic/export-earnings-data', {
                body: {
                    startDate: startDateString,
                    endDate : endDateString,
                    sortingType,
                    songs: selectedSongs.map((s) => s.id),
                    accountHolders: selectedAccountHolders.map((a) => a.id),
                    countries: selectedCountries.map((c) => c.name === 'All countries selected' ? 'All countries selected' : c.name),
                    playSources: selectedPlaySources.map((p) => p.name === 'All play sources selected' ? 'All play sources selected' : p.name),
                    reportingCompanies: selectedReportingCompanies.map((r) => r.id === "" ? 'Select All' : r.id)
                }
            });

            const transformedData = songsEarnings.map((item) => {
                const {
                    song,
                    country,
                    playSource,
                    reportingCompany,
                    revenue
                } = item;

                return {
                    'Song Title': song,
                    'Country': country ? country : 'Uncategorized',
                    'Reporting Company': reportingCompany ? reportingCompany : 'Uncategorized',
                    'Play Source': playSource ? playSource : 'Uncategorized',
                    'Period': selectedRange,
                    'Revenue': revenue
                };
            });

            const asCsvData = Papa.unparse(transformedData, {
                quotes: true
            });

            const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            const fileName = `earnings-${dateStamp}.csv`;

            const csvFile = new File([asCsvData], fileName, {
                type: 'text/plain'
            });

            createAndClickFileDownloadLink(csvFile, fileName);
        } catch (error) {
            console.log(error);
        } finally {
            setIsDownloading(false);
        }
    }

    const handleResetAutoCompletes = () => {
        setSelectedSongs([])
        setSelectedCountries([])
        setSelectedPlaySources([])
        setSelectedReportingCompanies([])
    }

    return (
        <Box className={styles.mainWrapper}>
            <div className={styles.headerAndSelectWrapper}>
                <Box component='section' className={styles.headerWrapper}>
                    <Typography variant='h5' className={styles.header}>Earnings</Typography>
                </Box>
                <Box className={selectedAccountHolders.length === 0 ? styles.topSideButtonsBeforeSelect : styles.topSideButtons}>
                    <Box className={clsx(styles.buttonWithLabelWrapper, selectedAccountHolders.length === 0 ? styles.beforeSelecting : '')}>
                        {
                            lessThanTwoAccountholders ?
                                <Typography>Account holder: {selectedAccountHolders[0]?.name ? selectedAccountHolders[0].name : 'N/A'}</Typography>
                                :
                                <>
                                    <Typography>Account holders</Typography>
                                    <AccountHolderAutocomplete
                                        disableAccountHolderAutoComplete={disableAccountHolderAutoComplete}
                                        selectedOptions={selectedAccountHolders}
                                        setSelectedOptions={setSelectedAccountHolders}
                                        handleResetAutoCompletes={handleResetAutoCompletes}
                                    />
                                </>
                        }
                    </Box>
                    {
                        selectedAccountHolders.length !== 0 ?
                            <Box className={styles.buttonWithLabelWrapper}>
                                <Typography>Date Period</Typography>
                                <BrandDateRange 
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={(newDate) => setStartDate(newDate)}
                                    setEndDate={(newDate) => setEndDate(newDate)}
                                    setPeriod={setSelectedRange}
                                />
                            </Box>
                            :
                            null
                    }
                </Box>
            </div>
            {
                selectedAccountHolders.length !== 0 ?
                    <>
                        <Box component='section' className={styles.categoriesRevenueWrapper}>
                            <Box className={styles.revenueWrapper}>
                                <Typography variant='body1'>Revenue</Typography>
                                {loadingRevenue ? (
                                    <BrandLoaderDots />
                                ) : (
                                    <Box className={styles.revenueNumberWarapper}>
                                        <Typography className={styles.currencySign}>$</Typography>
                                        <BrandPopover
                                            popoverData={earningsTotalRevenue}
                                            popoverDataFormated={formatedEarningsTotalRevenue}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box component='section' className={styles.categoriesWrapper}>
                                <Typography>Categories</Typography>
                                <Box className={styles.categoriesContentWrapper}>
                                    {!loadingCategories ? (
                                        categoriesData.map(({ category, count }, idx) => (
                                            <Fragment key={idx + category}>
                                                <Box className={styles.categoryWrapper}>
                                                    <Typography variant='body1'>{category}</Typography>
                                                    <Typography variant='body1' className={styles.categoryNumber}>{formatInteger(count)}</Typography>
                                                </Box>
                                                {idx + 1 < dummyCategories.length - 1 && (
                                                    <Divider orientation='vertical' className={styles.dividerLine} />
                                                )}
                                            </Fragment>
                                        ))
                                    ) : (
                                        <BrandLoaderDots />
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Box className={styles.buttonsWrapper}>
                            <Box className={styles.pieChartButtonWrapper}>
                                <Typography variant='body2'>
                                    Pie Chart
                                </Typography>
                                <Switch show={showChart} setShow={setShowChart} />
                            </Box>
                            <TempBrandButton
                                variant='contained'
                                className={styles.downloadButton}
                                onClick={downloadContent}
                                disabled={isDownloading}
                            >
                                {isDownloading ? (
                                    <BrandLoaderDots />
                                ) :
                                    'Download CSV'
                                }
                            </TempBrandButton>
                        </Box>
                        <Box className={styles.buttonsDiagramWrapper}>
                            <Box className={styles.leftSideButtons}>
                                <Box className={styles.resetBtnAndFiltersHeaderWrapper}>
                                    <Typography>Filter by</Typography>
                                    <Typography
                                        className={styles.resetBtn}
                                        onClick={handleResetAutoCompletes}
                                    >
                                        Reset
                                    </Typography>
                                </Box>
                                <SongsAutocomplete
                                    selectedOptions={selectedSongs}
                                    setSelectedOptions={setSelectedSongs}
                                    selectedAccountHolders={selectedAccountHolders}
                                />
                                <CountriesAutocomplete
                                    selectedOptions={selectedCountries}
                                    setSelectedOptions={setSelectedCountries}
                                    selectedSongs={selectedSongs}
                                    selectedAccountHolders={selectedAccountHolders}
                                />
                                {/* <SearchButton textContent='All Royalty type' /> */}
                                <ReportingCompaniesAutocomplete
                                    selectedOptions={selectedReportingCompanies}
                                    setSelectedOptions={setSelectedReportingCompanies}
                                    selectedAccountHolders={selectedAccountHolders}
                                    selectedPlaySources={selectedPlaySources}
                                    selectedCountries={selectedCountries}
                                    selectedSongs={selectedSongs}
                                />
                                <PlaySourcesAutocomplete
                                    selectedOptions={selectedPlaySources}
                                    setSelectedOptions={setSelectedPlaySources}
                                    selectedAccountHolders={selectedAccountHolders}
                                    selectedCountries={selectedCountries}
                                    selectedSongs={selectedSongs}
                                    selectedReportingCompanies={selectedReportingCompanies}
                                />
                            </Box>

                            <Box className={styles.songsTableWrapper}>
                                {showChart && (
                                    <SunburstChart data={chartData} loading={loadingChart} />
                                )}
                                {loadingTable ? (
                                    <Box style={{ width: '100%', height: '400px', position: 'relative' }}>
                                        <BrandLoaderDots className={styles.loaderDots} />
                                    </Box>
                                ) : (
                                    <SongsTable
                                        selectedRange={selectedRange}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        setSortingType={setSortingType}
                                        sortingType={sortingType}
                                        page={page}
                                        setPage={setPage}
                                        bodyCells={bodyData}
                                        rowsCount={rowsCount}
                                        setOffset={setOffset}
                                        offset={offset}
                                        rowsPerPageOptions={EARNINGS_ROWS_PER_PAGE_OPTIONS}
                                    />
                                )}
                            </Box>
                        </Box>
                    </>
                    :
                    null
            }
        </Box>
    )
};

export default Earnings;