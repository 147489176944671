import React, { useEffect, useState } from 'react';
import { getData, postData, putData } from '../utils/FetchUtils'
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { makeStyles } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import {
    TablePagination,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    IconButton,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { BrandInput, BrandSearchInput } from '../CoreComponents/BrandInput';
import { ArtistsAutoComplete } from '../CoreComponents/BrandAutoComplete';
import BrandTable from '../CoreComponents/BrandTable';
import { BrandModal } from '../CoreComponents/BrandModal'
import { BrandAlert } from '../CoreComponents/BrandAlert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { textIsEmpty } from '../utils/ValidationUtils';
import RefreshIcon from '@material-ui/icons/Refresh';
import { tableRowsPerClientViewHeight } from '../Portfolio/SongPortfolio';
import { useStoreContext } from '../../store/Store';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import CloseIcon from '@material-ui/icons/Close';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import { DeleteArtist } from './DeleteArtist';
import { MANAGE_ARTISTS_DEFAULT_ROWS_PER_PAGE, MANAGE_ARTISTS_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../utils/PaginationUtils';
import { BrandTooltipWithIcon } from '../CoreComponents/BrandTooltip';
import { ExactWordTooltipMessage } from '../../constants/messageConstants';
import { useDebounce } from '../../hooks/useDebounce';
import { ButtonSize } from '../../constants/buttonConstants';

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}

const useStyles = makeStyles(theme => ({
    input: {
        width: theme.spacing(55.625),
    },
    button: {
        width: '100%'
    },
    title: {
        textAlign: 'center'
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        textAlign: 'center',
        margin: 'auto auto 16px auto',
    },
    leftSide: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '&>*': {
            marginTop: theme.spacing(2),
        },
    },
    container: {
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: theme.palette.text.primary,
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '3px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    rotateIcon: {
        animation: "$spin 1s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    spotifyAvatar: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    editArtistContainer: {
        minWidth: theme.spacing(50),
        display: 'flex',
        flexDirection: "column",
        gap: theme.spacing(2),
        justifyContent: "flex-end",
    },
    editArtistSave: {
        display: 'flex',
        justifyContent: "flex-end",
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        gap: theme.spacing(2)
    },
    addRightHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        "& .MuiButtonBase-root": {
            width: theme.spacing(12)
        },
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        '& button': {
            width: '93px',
        }
    },
    addRightHolderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: '20vw',

    },
    artistMainTitle: {
        font: 'normal normal bold 25px Roboto'
    },
    cancelButton: {
        color: theme.palette.text.lightYellow,
        borderColor: theme.palette.text.lightYellow,
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    addAndSaveButton: {
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: theme.palette.text.dark,
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    refreshSpotifyButton: {
        color: theme.palette.text.dark,
    },
    artistsTableConnectButton: {
        color: theme.palette.text.dark,
        height: '32px',
        width: '211px'
    },
    selectedArtistContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '& .MuiSvgIcon-root:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        }
    },
    text: {
        marginBottom: theme.spacing(2.5)
    },
    deleteAndEditIcons: {
        color: 'white',
        fontSize: '0.875rem'
    },
    artistList: {
        maxWidth: 360, 
    },
    inputAndTooltip: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const AddArtist = ({ setAlert, alert, handleAddArtistSubmission, onClose }) => {
    const styles = useStyles();
    const [artist, setArtist] = useState(null);
    const artistName = useState('');
    const [disableAdd, setDisableAdd] = useState(true);

    useEffect(() => {
        setAlert('');
        artistName[0].trim() === '' ? setDisableAdd(true) : setDisableAdd(false)
    }, [artistName[0]])

    const deleteSelectedSpotifyArtist = () => {
        setArtist(null);
        artistName[1]('');
    };

    const addArtistHandler = async () => {
        let newArtist = { "name": artistName[0] };

        if (artist) {
            newArtist["spotifyId"] = artist.id;
        }

        if (artist && artist.img && artist.name) {
            newArtist["spotifyImg"] = artist.img;
            newArtist["spotifyArtistName"] = artist.name;
        }

        await handleAddArtistSubmission(newArtist);
    };

    return (
        <div className={styles.addRightHolderContainer}>
            <Typography variant="h6" component="div"> Add Artist</Typography>
            {alert && <BrandAlert>{alert}</BrandAlert>}
            <BrandInput label="Name" $value={artistName} validator={dataValidators.isRequired} />
            <ArtistsAutoComplete
                label="Find on Spotify"
                onSelectedArtist={artist => {
                    setArtist(artist);
                }}
            />
            {artist && (
                <div className={styles.selectedArtistContainer}>
                    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem key={artist.name} button>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar of ${artist.name}`}
                                    src={artist.img}
                                />
                            </ListItemAvatar>
                            <ListItemText id={artist.name} primary={artist.name} />
                        </ListItem>
                    </List>
                    <CloseIcon onClick={deleteSelectedSpotifyArtist} />
                </div>
            )}
            <div className={styles.addRightHolderActions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={addArtistHandler}
                    disabled={disableAdd}
                >
                    Add
                </TempBrandButton>
            </div>

        </div>
    );
}

const ConnectArtist = ({ artistName, selectedArtist, setForceRefreshArtists, setDisplayConnectArtistModal }) => {
    const [artist, setArtist] = useState(null);
    const styles = useStyles();
    const [alert, setAlert] = useState('');
    const [disableConnect, setDisableConnect] = useState(true);

    const connectArtistHandler = () => {
        const newArtist = {
            name: artistName,
            spotifyArtistName: artist?.name,
            spotifyId: artist?.id,
            img: artist?.img,
            id: selectedArtist.id
        };

        postData(process.env.REACT_APP_SERVER_HOST + '/api/artist/connect-spotify-artist', newArtist)
            .then(data => {
                if (data.error) {
                    setAlert(data.error);
                    return;
                }
                setForceRefreshArtists({});
                setDisplayConnectArtistModal(false);
            }, error => {
                setAlert(error.message);
            });
    };

    const deleteSelectedSpotifyArtist = () => {
        setArtist(null);
    };

    useEffect(() => {
        setDisableConnect(!artist);
    }, [artist]);

    return (
        <div className={styles.editArtistContainer}>
            <Typography variant='h5' component='div' color='primary'> Connect Spotify for {artistName}</Typography>
            {alert && <BrandAlert>{alert}</BrandAlert>}
            <div>
                <ArtistsAutoComplete
                    label="Find on Spotify"
                    onSelectedArtist={setArtist}
                />
                {artist && (
                    <div className={styles.selectedArtistContainer}>
                        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem key={artist.name} button>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={`Avatar of ${artist.name}`}
                                        src={artist.img}
                                    />
                                </ListItemAvatar>
                                <ListItemText id={artist.name} primary={artist.name} />
                            </ListItem>
                        </List>
                        <CloseIcon onClick={deleteSelectedSpotifyArtist} />
                    </div>
                )}
            </div>
            <div className={styles.editArtistSave}>
                <TempBrandButton
                    disabled={disableConnect}
                    className={styles.addAndSaveButton}
                    onClick={connectArtistHandler}
                >
                    Connect
                </TempBrandButton>
            </div>

        </div>
    );
}

const EditArtist = ({ data, setForceRefreshArtists, setDisplayEditArtistModal }) => {
    const styles = useStyles();
    const [artist, setArtist] = useState({ 
        img: data.spotifyImg, 
        name: data.name, 
        spotifyArtistName: data.spotifyArtistName
    });
    const artistName = useState(data.name);
    const [alert, setAlert] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(true);

    const connectArtistHandler = () => {
        const spotifyArtistName = artist?.spotifyArtistName ? artist?.spotifyArtistName 
                                    : artist?.id ? artist?.name 
                                    : null;
        const newArtist = {
            name: artistName[0],
            spotifyArtistName: spotifyArtistName,
            spotifyId: artist?.id ? artist?.id : null,
            spotifyImg: artist?.img,
            id: data.id
        };
        putData(process.env.REACT_APP_SERVER_HOST + '/api/artist/', newArtist)
            .then(data => {
                if (data.error) {
                    setAlert(data.error)
                    return;
                }
                setForceRefreshArtists({});
                setDisplayEditArtistModal(false);
            }, error => {
                setAlert(error.message);
            })
    };

    const deleteSelectedSpotifyArtist = () => {
        setArtist(null);
    };

    useEffect(() => {
        const currentArtist = {
            img: data.spotifyImg, 
            name: data.name, 
            spotifyArtistName: data.spotifyArtistName
        }
        const artistCondition = JSON.stringify(artist) === JSON.stringify(currentArtist);

        if (artistName[0] === '' || artistCondition && artistName[0] === data.name) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }, [artistName, artist]);

    return (
        <div className={styles.editArtistContainer}>
            <Typography variant='h5' component='div'> Edit Artist</Typography>
            {alert ? <BrandAlert>{alert}</BrandAlert> : null}
            <BrandInput
                $value={artistName}
                required
                validator
                ={dataValidators.isRequired}
                label='Artist'
            />
            <div>
                <ArtistsAutoComplete
                    label={`Link spotify`}
                    onSelectedArtist={setArtist}
                />

                {(artist && (artist.id || artist.spotifyArtistName)) && (
                    <div className={styles.selectedArtistContainer}>
                        <List dense className={styles.artistList}>
                            <ListItem key={artist.name} button>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={`Avatar of ${artist.name}`}
                                        src={artist.img}
                                    />
                                </ListItemAvatar>
                                <ListItemText 
                                    id={artist.spotifyArtistName ? artist.spotifyArtistName : artist.name} 
                                    primary={artist.spotifyArtistName ? artist.spotifyArtistName : artist.name} 
                                />
                            </ListItem>
                        </List>
                        <CloseIcon onClick={deleteSelectedSpotifyArtist} />
                    </div>
                )}
            </div>
            <div className={styles.editArtistSave}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => setDisplayEditArtistModal(false)}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    disabled={saveDisabled}
                    onClick={connectArtistHandler}
                >
                    Save
                </TempBrandButton>
            </div>
        </div>
    );
}

const SpotifyAvatar = ({ rowData, onRefresh, spotifyRefreshData }) => {
    const styles = useStyles();
    const disableRefreshButtons = Object.keys(spotifyRefreshData).length === 0 ? false : true;

    return (
        <div className={styles.spotifyAvatar}>
            <Avatar
                alt={`Avatar of ${rowData.name}`}
                src={rowData.spotifyImg}
            />
            <TempBrandButton
                className={styles.refreshSpotifyButton}
                disabled={disableRefreshButtons}
                onClick={() => {
                    postData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/refresh?artistId=${rowData.id}&forceRefresh=${true}`)
                        .then(data => {
                            if (data.error) {
                                // setAlert(data.error)
                                return;
                            }
                            onRefresh();
                        }, error => {
                            // setAlert(error.message)
                        })
                }}
                startIcon={<RefreshIcon className={spotifyRefreshData[rowData.id] || spotifyRefreshData.all ? styles.rotateIcon : null} />}
            >
                Refresh Spotify Songs
            </TempBrandButton>
        </div>
    );
}

const ManageArtists = () => {
    const styles = useStyles();
    const [forceRefreshArtists, setForceRefreshArtists] = useState({});
    const [displayAddArtistModal, setDisplayAddArtistModal] = useState(false);
    const [displayConnectArtistModal, setDisplayConnectArtistModal] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [artists, setArtists] = useState([]);
    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);
    const [sortBy, setSortBy] = useState('name');
    const [sortType, setSortType] = useState('ASC');
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(MANAGE_ARTISTS_ROWS_PER_PAGE_OPTIONS);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(MANAGE_ARTISTS_DEFAULT_ROWS_PER_PAGE);
    const [addArtistAlert, setAddArtistAlert] = useState('');
    const [displayEditArtistModal, setDisplayEditArtistModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [spotifyRefreshData, setSpotifyRefreshData] = useState({});
    const [state, setState] = useStoreContext();
    const [selectedRowDataForDelete, setSelectedRowDataForDelete] = useState({});
    const [displayDeleteArtistModal, setDisplayDeleteArtistModal] = useState(false);

    const refreshSpotifyData = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/refresh`)
            .then(data => {
                setSpotifyRefreshData(data);
            }, error => {
                // setAlert(error.message)
            })
    }

    const [headCells] = useState([
        {
            id: 'name',
            label: 'Artist Name',
        },
        {
            id: 'spotifyId',
            label: 'Spotify',
            CellRender: ({ rowData, cellData, additionalData }) => {
                return (cellData ?
                    <SpotifyAvatar
                        key={cellData}
                        rowData={rowData}
                        spotifyRefreshData={additionalData}
                        onRefresh={() => {
                            setForceRefreshArtists({});
                            refreshSpotifyData();
                        }}
                    />
                    :
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        className={styles.artistsTableConnectButton}
                        key={cellData}
                        onClick={e => {
                            e.preventDefault();
                            setSelectedArtist(rowData);
                            setDisplayConnectArtistModal(true);
                        }}
                    >
                        Connect
                    </TempBrandButton>
                );
            }
        },
        {
            id: 'delete',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        className={styles.deleteAndEditIcons}
                        onClick={() => {
                            setSelectedRowDataForDelete(rowData);
                            setDisplayDeleteArtistModal(true);
                        }}>
                        <DeleteOutlineOutlinedIcon fontSize='small' />
                        Delete
                    </IconButton>
                )
            }
        },
        {
            id: 'edit',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return <IconButton
                    className={styles.deleteAndEditIcons}
                    onClick={() => {
                        setSelectedRowData(rowData);
                        setDisplayEditArtistModal(true);
                    }}>
                    <EditOutlinedIcon fontSize='small' />
                    Edit
                </IconButton>
            }
        }
    ]);

    const { trigger, loading } = useAsyncAction(async () => {
        const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/artist/?&offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}&filter=${searchQuery}`);
        setRowCounts(fetchedData.total);
        setArtists(fetchedData.items);
    });

    useEffect(() => {
        tableRowsPerClientViewHeight(setRowsPerPage, setRowsPerPageOptions, rowsPerPageOptions, 200)
    }, [])

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page])

    useEffect(() => {
        if (rowsPerPage !== 0) {
            trigger();
        }
    }, [forceRefreshArtists, offset, rowsPerPage, sortType, sortBy, searchQuery])

    useEffect(() => {
        setPage(0);
    }, [searchQuery])

    useEffect(() => {
        const intervalHandler = setInterval(() => {
            refreshSpotifyData();
        }, 30000);
        refreshSpotifyData();
        return () => clearInterval(intervalHandler);
    }, [])

    async function handleAddArtistSubmission(artist) {
        postData(process.env.REACT_APP_SERVER_HOST + '/api/artist/', artist)
            .then(data => {
                if (data.error) {
                    setAddArtistAlert(data.error)
                    return;
                }
                setForceRefreshArtists({});
                setDisplayAddArtistModal(false);
            }, error => {
                setAddArtistAlert(error.message)
            })
    };

    return (
        <div className={styles.container}>
            <BrandModal
                open={displayAddArtistModal}
                onClose={() => setDisplayAddArtistModal(false)}
            >
                <AddArtist
                    setAlert={setAddArtistAlert}
                    alert={addArtistAlert}
                    handleAddArtistSubmission={handleAddArtistSubmission}
                    onClose={() => setDisplayAddArtistModal(false)}
                />
            </BrandModal>
            <BrandModal
                open={displayEditArtistModal}
                onClose={() => setDisplayEditArtistModal(false)}
            >
                <EditArtist
                    data={selectedRowData}
                    setForceRefreshArtists={setForceRefreshArtists}
                    setDisplayEditArtistModal={setDisplayEditArtistModal}
                />
            </BrandModal>
            <BrandModal
                open={displayConnectArtistModal}
                onClose={() => setDisplayConnectArtistModal(false)}
            >
                <ConnectArtist
                    artistName={selectedArtist?.name}
                    selectedArtist={selectedArtist}
                    setForceRefreshArtists={setForceRefreshArtists}
                    setDisplayConnectArtistModal={setDisplayConnectArtistModal}
                />
            </BrandModal>
            <DeleteArtist 
                selectedRowDataForDelete={selectedRowDataForDelete}
                open={displayDeleteArtistModal}
                setDisplayDeleteArtistModal={setDisplayDeleteArtistModal}
                trigger={trigger}
            />
            <Typography
                variant='h6'
                component='div'
                className={styles.artistMainTitle}
            >
                Artists
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                <div className={styles.inputAndTooltip}>
                    <BrandSearchInput
                        placeholder='Search for artist'
                        onChange={(event) => setSearch(event.target.value)}
                        classes={{ input: styles.input }}
                        />
                    <BrandTooltipWithIcon title={ExactWordTooltipMessage}/>
                </div>
                    <TempBrandButton
                        capitalize={true}
                        startIcon={<PersonAdd />}
                        onClick={() => setDisplayAddArtistModal(true)}
                    >
                        Add Artist
                    </TempBrandButton>
                </div>
            </div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        rows={artists}
                        headCells={headCells}
                        checkboxless={true}
                        additionalData={spotifyRefreshData}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </div>
    );
}

export default ManageArtists;