import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { BrandSearchInput } from '../../CoreComponents/BrandInput';
import { QuarterPicker } from '../../CoreComponents/QuarterPicker';
import { getData } from '../../utils/FetchUtils';
import { useStoreContext } from '../../../store/Store';
import { useDebounce } from '../../../hooks/useDebounce';
import CustomFilterDropdown from '../../Portfolio/CustomFilterDropdown';
import CsvDataTable from './CsvDataTable';
import { Typography } from "@material-ui/core";
import { BrandAutocomplete } from '../../CoreComponents/BrandAutoComplete';
import ReportingCompaniesAutocomplete from '../Autocompletes/ReportingCompaniesAutocomplete';
import { reportNames } from '../../utils/ReportNamesUtils';
import { userAccessByType } from '../../utils/AccessUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TempBrandButton } from '../../CoreComponents/BrandButton';
import { BrandDateRange } from '../../CoreComponents/BrandDatePicker';

const useStyles = makeStyles((theme) => ({
  selectsAndToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  autocompleteWrapper: {
    width: '215px',
  },
  selectGroup: {
    width: '100%',
  },
  defaultSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.lightGray,
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  singleAccountHolderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(4),
    alignItems: 'center'
  },
  singleAccountHolderLabel: {
    font: 'normal normal normal 14px/16px Roboto',
    color: theme.palette.text.lightYellow,
    letterSpacing: '1px',
    width: '120px'
  },
  accountHolder: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    width: '220px'
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: theme.spacing(1.3),
    rowGap: '52.5px'
  },
  dateSelectOneAccHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.text.lightGray,
    gap: 4,
    '& .MuiButtonBase-root': {
      color: 'white'
    }
  },
  dateSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.text.lightGray,
    gap: 10,
    '& .MuiButtonBase-root': {
      color: 'white'
    }
  },
  defaultSelectLabel: {
    letterSpacing: '1px',
    marginLeft: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.lightGray,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '40px',
    alignItems: 'center',
    width: '100%',
  },
  dateAlert: {
    color: theme.palette.primary.alert
  },
  searchInput: {
    width: 370,
  },
  accountHoldersAutoComplete: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: `1px  ${theme.palette.primary.border}`,
      }
    },
    '& .MuiInputBase-root': {
      height: theme.spacing(5),
      border: '1px solid grey',
      borderRadius: theme.spacing(3),
      height: '40px',
      width: '220px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.border,
      border: 'none'
    },


    '& .MuiFormLabel-root': {
      top: '-6px'
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      bottom: theme.spacing(1),
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: 'white'
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white',
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')"
          }
        }
      }
    },
  },
  uploadButton: {
    width: '150px',
  },
  reportingCompaniesAndUpload: { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    width: '100%',
  },
  reportingCompanyFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  noWrapText: { 
    textWrap: 'nowrap', 
    marginRight: '0.5vw',
    '& .MuiInputBase-root': {
      minWidth: '215px',
    }
  }
}))

const CsvStatements = () => {
  const styles = useStyles();


  const [state, setState] = useStoreContext();
  const history = useHistory();
  const isAdmin = state.user && state.user.userType === userAccessByType.USER_TYPE_ADMIN_ACCESS;
  const [accountHolder, setAccountHolder] = useState('');
  const [accountHolders, setAccountHolders] = useState([]);


  const [reportNameSearch, setReportNameSearch] = useState('');
  const reportNameSearchQuery = useDebounce(reportNameSearch, 500);

  const [selectedReportingCompanies, setSelectedReportingCompanies] = useState([]);
  const [reportingCompanies, setReportingCompanies] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(0);
  
  const [alert, setAlert] = useState(null);
  
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  
  useEffect(() => {
    const getAccountHolders = async () => {
      const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
      setAccountHolders(data);
    }
    getAccountHolders();
  }, []);

  useEffect(() => {
    setState(state => (state.selectedAccountholderPortfolio = accountHolder, { ...state }));
  }, [accountHolder])

  useEffect(() => {
    if (accountHolders && accountHolders.length === 1)
      setAccountHolder(accountHolders[0].id)
  }, [accountHolders])


  const handleUploadClick = () => {
    history.push('/upload');
  }

  return (
    <div className={styles.selectsAndToolbarContainer}>
      <div className={styles.selectGroup}>
        <div className={styles.defaultSelect}>
          {
            accountHolders && accountHolders.length < 2 ?
              (
                <div className={styles.singleAccountHolderWrapper}>
                  <div className={styles.singleAccountHolderLabel}>Account Holder(s): </div>
                  <Typography className={styles.accountHolder}>
                    {accountHolders[0]?.name ? accountHolders[0]?.name : 'N/A'}
                  </Typography>
                </div>
              )
              :
              <>
                <Typography>Account Holder(s)</Typography>
                <div className={styles.autocompleteWrapper}>
                  <BrandAutocomplete
                    placeholder={'Select Account Holder'}
                    className={{ width: '150px' }}
                    selectedAutoCompleteOption={accountHolder}
                    onSelectedAutoCompleteOption={(accountHolderId) => setAccountHolder(accountHolderId)}
                    autoCompleteOptions={accountHolders}
                  />
                </div>
              </>
          }
          <div className={styles.checkboxContainer}>
            <div className={accountHolders && accountHolders.length < 2 ? styles.dateSelectOneAccHolder : styles.dateSelect}>
              <Typography className={accountHolders && accountHolders.length < 2 ? styles.defaultSelectLabelOneAccHolder : styles.defaultSelectLabel}>Date Range</Typography>
              <BrandDateRange startDate={startDate} endDate={endDate} setStartDate={(date)=>setStartDate(date)} setEndDate={(date)=>setEndDate(date)}/>
            </div>
          </div>
          {
            alert ?
              <div className={styles.dateAlert}>{alert}</div>
              :
              null
          }
        </div>

        <div className={styles.toolbar}>
          <div style={{ display: 'inherit', width: '100%' }}>
            <div className={styles.navContainer}>
              <div className={styles.filtersWrapper}>
                <BrandSearchInput
                  placeholder={`Search for statement`}
                  value={reportNameSearch}
                  onChange={(event) => setReportNameSearch(event.target.value)}
                  classes={{ input: styles.searchInput }}
                />
                <div className={styles.noWrapText}>
                  <CustomFilterDropdown
                    label='Report Type'
                    selectedOption={selectedFilter}
                    setSelectedOption={setSelectedFilter}
                    options={[
                      { label: 'All', value: 0 },
                      ...reportNames.map(reportName => ({ label: reportName.name, value: reportName.id }))
                    ]}
                  />
                </div>
                {
                  isAdmin &&
                  <div className={styles.reportingCompaniesAndUpload}>
                    <div className={styles.reportingCompanyFilter}>
                      <Typography className={styles.noWrapText}>Reporting Company</Typography>
                      <ReportingCompaniesAutocomplete
                        options={reportingCompanies}
                        setOptions={setReportingCompanies}
                        selectedOptions={selectedReportingCompanies}
                        setSelectedOptions={setSelectedReportingCompanies}
                      />
                    </div>
                    <TempBrandButton className={styles.uploadButton} onClick={handleUploadClick}>
                      Upload CSV
                    </TempBrandButton>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <CsvDataTable
          accountHolder={accountHolder}
          startDate={startDate}
          endDate={endDate}
          selectedFilter={selectedFilter}
          reportNameSearch={reportNameSearchQuery}
          setReportingCompanies={setReportingCompanies}
          selectedReportingCompanies={selectedReportingCompanies}
        />

      </div>
    </div>
  )
}

export default CsvStatements